import { Component, Input, OnInit } from '@angular/core';
import {
  AdlCompositeStratification,
  AdlCompositeStratificationLabels,
} from '../../../models/AdlComposite';

@Component({
  selector: 'adl-improvement-cell',
  templateUrl: './adl-improvement-cell.component.html',
  styleUrls: ['./adl-improvement-cell.component.scss'],
})
export class AdlImprovementCellComponent implements OnInit {
  @Input() adlCompositeStratification: AdlCompositeStratification =
    AdlCompositeStratification.Low;

  displayValue = '';

  constructor() {}

  ngOnInit(): void {
    this.displayValue =
      AdlCompositeStratificationLabels[this.adlCompositeStratification];
  }
}
