import { Injectable } from '@angular/core';

// Provided explicitly in AppModule, making this a true singleton even across lazy-loaded modules
@Injectable()
export class GlobalStateService {
  public populationFilterString?: string;
  public populationGridString?: string;

  constructor() {
    console.log('gs service', this);
  }
}
