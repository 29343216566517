<app-navbar></app-navbar>
<div [class.mt-4]="bannerService.isBannerLoaded">
  <atlas-banner-container></atlas-banner-container>
</div>
<div class="px-3 py-4">
  <!-- During auth, display a spinner -->
  <div
    class="spinner-container"
    *ngIf="!(authService.currentUser$ | async)?.metadata"
  >
    <atlas-spinner aria-label="Loading users"></atlas-spinner>
  </div>

  <!-- Display the router outlet once we have a user -->
  <router-outlet></router-outlet>

  <!-- Typeform launcher -->
  <typeform-survey></typeform-survey>
</div>
