import { titleCase } from '../utils/string.utils';

export interface ReferringPhysician {
  firstName: string;
  lastName: string;
}

export const getReferringPhysicianDisplayName = (
  rp: ReferringPhysician
): string => {
  let str = rp.lastName;

  if (rp.firstName) {
    if (rp.lastName) {
      str += ', ';
    }
    str += rp.firstName;
  }

  return titleCase(str ?? '');
};
