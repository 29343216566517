import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CaseManager,
  getCaseManagerDisplayName,
} from '../../models/CaseManager';
import { environment } from '../../../environments/environment';
import { AtlasResponseWrapper } from '../../models/AtlasResponseWrapper';
import { map, shareReplay } from 'rxjs/operators';
import { titleCase } from '../../utils/string.utils';
import {
  getReferringPhysicianDisplayName,
  ReferringPhysician,
} from '../../models/ReferringPhysician';
import { SelectOption } from '@wellsky/ai-dataview-ui/filters/src/models/SelectOption';

const FILTER_OPTIONS_URL = `${environment.apiURL}/population/filter-options`;

@Injectable({
  providedIn: 'root',
})
export class FilterOptionsService {
  public caseManagerOptions$: Observable<SelectOption[]> = this.http
    .get<AtlasResponseWrapper<CaseManager[]>>(
      `${FILTER_OPTIONS_URL}/case-managers`
    )
    .pipe(
      map(({ result }) =>
        result.map((cm) => {
          const caseManager = getCaseManagerDisplayName(cm);
          return {
            label: caseManager,
            value: caseManager,
          };
        })
      ),
      shareReplay(1)
    );

  public payerNameOptions$: Observable<SelectOption[]> = this.http
    .get<AtlasResponseWrapper<string[]>>(`${FILTER_OPTIONS_URL}/payers`)
    .pipe(
      map(({ result }) =>
        result.map((payerName) => ({
          label: titleCase(payerName),
          value: payerName,
        }))
      ),
      shareReplay(1)
    );

  public referringFacilityOptions$: Observable<SelectOption[]> = this.http
    .get<AtlasResponseWrapper<string[]>>(
      `${FILTER_OPTIONS_URL}/referring-facilities`
    )
    .pipe(
      map(({ result }) =>
        result.map((referringFacility) => ({
          label: titleCase(referringFacility),
          value: referringFacility,
        }))
      ),
      shareReplay(1)
    );

  public referringPhysicianOptions$: Observable<SelectOption[]> = this.http
    .get<AtlasResponseWrapper<ReferringPhysician[]>>(
      `${FILTER_OPTIONS_URL}/referring-physicians`
    )
    .pipe(
      map(({ result }) =>
        result.map((rp) => {
          const name = getReferringPhysicianDisplayName(rp);
          return {
            label: name,
            value: name,
          };
        })
      ),
      shareReplay(1)
    );

  constructor(private http: HttpClient) {}
}
