import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DataGridFiltersService,
  MultiSelectFilterField,
} from '@wellsky/ai-dataview-ui/filters';

@Component({
  selector: 'population-filter-drawer',
  templateUrl: './filter-drawer.component.html',
  styleUrls: ['./filter-drawer.component.scss'],
})
export class FilterDrawerComponent {
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor(public filterService: DataGridFiltersService) {}

  public getMultiSelectOptions(slug: string) {
    return this.filterService.field<MultiSelectFilterField>(slug).options;
  }

  public episodeDayFieldError(): string | null {
    const errors = this.filterService.control('episodeDay').errors;
    if (!errors || Object.keys(errors).length === 0) {
      return null;
    }

    const [error, { field, message }] = Object.entries(errors)[0];
    if (error === 'invalidRange') {
      return 'End day cannot be less than start day';
    }
    switch (field) {
      case 'min':
        return `Start day ${message}`;
      case 'max':
        return `End day ${message}`;
    }

    // Shouldn't end up here
    return 'The episode day range is invalid';
  }
}
