<div class="filter-drawer">
  <!-- Heading -->
  <div class="heading">
    <div class="title">Filters</div>
    <button
      atlas-icon-button
      aria-label="Close filters"
      (click)="close.emit()"
      data-test-id="filter-drawer-close"
    >
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>

  <!-- Form controls -->
  <form [formGroup]="filterService.formGroup" class="form-fields" range-input>
    <!-- Clear all button -->
    <div class="clear-all-button-container">
      <button
        class="clear-all-button"
        (click)="filterService.reset()"
        data-test-id="filter-drawer-clear-all-button"
      >
        Clear All Filters
      </button>
    </div>

    <!-- Search -->
    <mat-form-field appearance="legacy">
      <mat-label>Search</mat-label>
      <input
        matInput
        data-test-id="filter-drawer-field-search-input"
        [formControl]="filterService.control('search')"
      />
      <mat-icon
        *ngIf="!filterService.formGroup.value.search.length"
        class="field-icon"
        matSuffix
      >
        search
      </mat-icon>
      <button
        *ngIf="!!filterService.formGroup.value.search.length"
        atlas-icon-button
        matSuffix
        class="clear-button"
        aria-label="Clear"
        (click)="filterService.resetField('search')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Organizational Unit -->
    <organizational-unit-search
      appearance="legacy"
      label="Locations"
      [formControl]="filterService.control('organizationalUnitIDs')"
    ></organizational-unit-search>

    <!-- Episode Day Range -->
    <mat-label>Episode Day</mat-label>
    <range-input
      [formControl]="filterService.control('episodeDay')"
      [min]="1"
      minLabel="Start"
      maxLabel="End"
    >
    </range-input>
    <mat-error *ngIf="!!episodeDayFieldError()">
      {{ episodeDayFieldError() }}
    </mat-error>

    <!-- Insurance -->
    <search-dropdown
      [items]="getMultiSelectOptions('payerNames')"
      [fc]="filterService.control('payerNames')"
      placeholderLabel="Insurance"
      data-test-id="filter-drawer-field-payer-names-searchselect"
    >
    </search-dropdown>

    Referring Physicians
    <search-dropdown
      [items]="getMultiSelectOptions('referringPhysicians')"
      [fc]="filterService.control('referringPhysicians')"
      placeholderLabel="Referring Physician"
      data-test-id="filter-drawer-field-referring-physicians-searchselect"
    ></search-dropdown>

    <!-- Referral Source -->
    <search-dropdown
      [items]="getMultiSelectOptions('referringFacilities')"
      [fc]="filterService.control('referringFacilities')"
      placeholderLabel="Referral Source"
      data-test-id="filter-drawer-field-referring-facilities-searchselect"
    ></search-dropdown>

    <!-- Case Manager -->
    <search-dropdown
      [items]="getMultiSelectOptions('caseManagers')"
      [fc]="filterService.control('caseManagers')"
      placeholderLabel="Case Manager"
      data-test-id="filter-drawer-field-case-managers-searchselect"
    ></search-dropdown>

    <!-- Visit Utilization Level -->
    <search-dropdown
      [items]="getMultiSelectOptions('visitUtilizationLevels')"
      [fc]="filterService.control('visitUtilizationLevels')"
      placeholderLabel="Utilization Assessment"
      data-test-id="filter-drawer-field-visit-utilization-level-searchselect"
    ></search-dropdown>

    <!-- LUPA Risk -->
    <search-dropdown
      [items]="getMultiSelectOptions('lupaVisitCompletionStatuses')"
      [fc]="filterService.control('lupaVisitCompletionStatuses')"
      placeholderLabel="LUPA Risk"
      data-test-id="filter-drawer-field-lupa-risk-searchselect"
    ></search-dropdown>

    <!-- Hospitalization Risk -->
    <search-dropdown
      [items]="getMultiSelectOptions('hospitalizationRiskStratifications')"
      [fc]="filterService.control('hospitalizationRiskStratifications')"
      placeholderLabel="Hospitalization Risk"
      data-test-id="filter-drawer-field-hospitalization-risk-searchselect"
    ></search-dropdown>

    <!-- Hospice Suitability -->
    <search-dropdown
      [items]="getMultiSelectOptions('hospiceSuitabilityStratifications')"
      [fc]="filterService.control('hospiceSuitabilityStratifications')"
      placeholderLabel="Hospice Suitability"
      data-test-id="filter-drawer-field-hospice-suitability-searchselect"
    ></search-dropdown>

    <!-- ADL Improvement -->
    <search-dropdown
      [items]="getMultiSelectOptions('adlCompositeStratifications')"
      [fc]="filterService.control('adlCompositeStratifications')"
      placeholderLabel="ADL Improvement"
      data-test-id="filter-drawer-field-adl-improvement-searchselect"
    ></search-dropdown>

    <!-- PDGM Clinical Group -->
    <search-dropdown
      [items]="getMultiSelectOptions('pdgmClinicalGroups')"
      [fc]="filterService.control('pdgmClinicalGroups')"
      placeholderLabel="PDGM Clinical Group"
      data-test-id="filter-drawer-field-pdgm-clinical-group-searchselect"
    ></search-dropdown>

    <!-- Comorbidity level -->
    <search-dropdown
      [items]="getMultiSelectOptions('comorbidityLevels')"
      [fc]="filterService.control('comorbidityLevels')"
      placeholderLabel="Comorbidity Level"
      data-test-id="filter-drawer-field-comorbidity-level-searchselect"
    ></search-dropdown>

    <!-- PDGM Functional Impairment Level -->
    <search-dropdown
      [items]="getMultiSelectOptions('pdgmFunctionalImpairmentLevels')"
      [fc]="filterService.control('pdgmFunctionalImpairmentLevels')"
      placeholderLabel="Functional Impairment Level"
      data-test-id="filter-drawer-field-pdgm-functional-impairment-level-searchselect"
    ></search-dropdown>

    <!-- Referral Type -->
    <search-dropdown
      [items]="getMultiSelectOptions('referralSources')"
      [fc]="filterService.control('referralSources')"
      placeholderLabel="Referral Type"
      data-test-id="filter-drawer-field-referral-type-searchselect"
    ></search-dropdown>
  </form>
</div>
