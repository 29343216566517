// The order of this enum is intentional. Ordered by alpha order of the Label text defined within PdgmClinicalGroupLabels below, then "No Group"
export enum PdgmClinicalGroup {
  BehaveHealth = 'BEHAVE_HEALTH',
  Complex = 'COMPLEX',
  MmtaCardiac = 'MMTA_CARDIAC',
  MmtaEndo = 'MMTA_ENDO',
  MmtaGiGu = 'MMTA_GI_GU',
  MmtaInfect = 'MMTA_INFECT',
  MmtaOther = 'MMTA_OTHER',
  MmtaResp = 'MMTA_RESP',
  MmtaAfter = 'MMTA_AFTER',
  MsRehab = 'MS_REHAB',
  NeuroRehab = 'NEURO_REHAB',
  Wound = 'WOUND',
  NoGroup = 'NO_GROUP',
}

export const PdgmClinicalGroupLabels: Record<PdgmClinicalGroup, string> = {
  [PdgmClinicalGroup.BehaveHealth]: 'Behavioral Health',
  [PdgmClinicalGroup.Complex]: 'Complex Nursing Interventions',
  [PdgmClinicalGroup.MmtaCardiac]: 'MMTA - Cardiac',
  [PdgmClinicalGroup.MmtaEndo]: 'MMTA - Endocrine',
  [PdgmClinicalGroup.MmtaGiGu]: 'MMTA - GI/GU',
  [PdgmClinicalGroup.MmtaInfect]: 'MMTA - Infectious',
  [PdgmClinicalGroup.MmtaOther]: 'MMTA - Other',
  [PdgmClinicalGroup.MmtaResp]: 'MMTA - Respiratory',
  [PdgmClinicalGroup.MmtaAfter]: 'MMTA - Surgical Aftercare',
  [PdgmClinicalGroup.MsRehab]: ' Musculoskeletal Rehab',
  [PdgmClinicalGroup.NeuroRehab]: 'Neuro Rehab',
  [PdgmClinicalGroup.Wound]: 'Wounds',
  [PdgmClinicalGroup.NoGroup]: 'Non PDGM',
};
