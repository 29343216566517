import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from '../models/AuthorizedUser';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const allowedRoles = route.data.allowedRoles as Array<UserRole>;

    return this.authService.currentUser$.pipe(
      map((optionalUser) => {
        if (allowedRoles) {
          if (!optionalUser) {
            console.error('no authorized user; cannot allow access to route');
            return false;
          }

          return allowedRoles.some((role) => optionalUser.hasRole(role));
        }
        return true;
      })
    );
  }
}

export const RoleGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot
): Observable<boolean> => {
  return inject(PermissionsService).canActivate(next);
};
