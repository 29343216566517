import { isNonNullable } from '../utils/general.utils';

export interface RangeValue {
  min?: number;
  max?: number;
}

export const rangesAreEqual = (a: RangeValue, b: RangeValue) => {
  return a.min === b.min && a.max === b.max;
};

export const rangeValueDisplayString = (range: RangeValue): string => {
  if (isNonNullable(range.min) && isNonNullable(range.max)) {
    if (range.min === range.max) {
      return range.min.toLocaleString();
    }
    return `${range.min.toLocaleString()} through ${range.max.toLocaleString()}`;
  }
  if (isNonNullable(range.min)) {
    return `${range.min.toLocaleString()} or higher`;
  }
  if (isNonNullable(range.max)) {
    return `${range.max.toLocaleString()} or less`;
  }
  return 'any';
};
