export enum HospitalizationRiskStratification {
  Low = 'low',
  Guarded = 'guarded',
  Elevated = 'elevated',
  High = 'high',
  VeryHigh = 'veryHigh',
}

export const HospitalizationRiskStratificationLabels: Record<
  HospitalizationRiskStratification,
  string
> = {
  [HospitalizationRiskStratification.Low]: 'Low',
  [HospitalizationRiskStratification.Guarded]: 'Guarded',
  [HospitalizationRiskStratification.Elevated]: 'Elevated',
  [HospitalizationRiskStratification.High]: 'High',
  [HospitalizationRiskStratification.VeryHigh]: 'Very High',
};

export const HospitalizationRiskStratificationColors: Record<
  HospitalizationRiskStratification,
  string
> = {
  [HospitalizationRiskStratification.Low]: '#27ae60',
  [HospitalizationRiskStratification.Guarded]: '#fdc400',
  [HospitalizationRiskStratification.Elevated]: '#fb9a00',
  [HospitalizationRiskStratification.High]: '#f44236',
  [HospitalizationRiskStratification.VeryHigh]: '#ba1509',
};

export interface HospitalizationRiskChartData {
  lowCount: number;
  guardedCount: number;
  elevatedCount: number;
  highCount: number;
  veryHighCount: number;
}
