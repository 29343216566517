export interface OrganizationalUnit {
  id: number;
  parentId?: number;
  parentName?: string;
  path: string;
  name: string;
  description: string;
  type: OrganizationalUnitType;
}

export interface OrganizationalUnitDetailedPathPart {
  name: string;
  id: number;
  path: string;
}

export enum OrganizationalUnitType {
  Organizational = 'organizational',
  Agency = 'agency',
  Clinic = 'clinic',
  ClinicBranch = 'clinic_branch',
}

export const getPathDepth = (path: string): number => path.split('.').length;

export const getParentPath = (ou: OrganizationalUnit): string | null => {
  const pathParts = ou.path.split('.');
  if (pathParts.length === 1) {
    return null;
  }
  return pathParts.slice(0, -1).join('.');
};
