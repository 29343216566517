import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PopulationFilters, PopulationService } from '../../population.service';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import {
  LupaRiskChartData,
  LupaVisitCompletionStatus,
} from '../../../models/LupaRisk';
import { rangesAreEqual, RangeValue } from '../../../models/Range';
import { omit } from '../../../utils/object.utils';
import { lastValueFrom } from 'rxjs';

export interface LupaRiskChartSelection {
  period: number;
  completionStatus: LupaVisitCompletionStatus;
  episodeDayRange: RangeValue;
}

@Component({
  selector: 'population-lupa-risk-chart-card',
  templateUrl: './lupa-risk-chart-card.component.html',
  styleUrls: ['./lupa-risk-chart-card.component.scss'],
})
export class LupaRiskChartCardComponent implements OnChanges {
  private wasClicked: boolean = false;

  @Input() filters: PopulationFilters | null = null;

  @Output() selected = new EventEmitter<LupaRiskChartSelection | null>();

  firstPeriodSeries: ApexAxisChartSeries = [];
  secondPeriodSeries: ApexAxisChartSeries = [];

  yAxisMax: number = 0;
  yAxisMin: number = 0;
  yAxisTickCount: number = 7;

  isLoading: boolean = true;

  private activeFilterSelection?: LupaRiskChartSelection;

  constructor(
    private populationService: PopulationService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.filters?.currentValue ||
      changes.filters.previousValue === changes.filters.currentValue
    ) {
      return;
    }

    let needsReload = false;

    for (const filter of Object.keys(changes.filters.currentValue)) {
      if (!this.wasClicked) {
        if (
          changes.filters.currentValue[filter] !==
          changes.filters.previousValue?.[filter]
        ) {
          needsReload = true;
          break;
        }
      }
    }

    this.wasClicked = false;

    // Update active filter selection
    if (
      changes.filters.currentValue.episodeDay &&
      changes.filters.currentValue.lupaVisitCompletionStatuses?.length === 1 &&
      (rangesAreEqual(changes.filters?.currentValue?.episodeDay, {
        min: 1,
        max: 10,
      }) ||
        rangesAreEqual(changes.filters.currentValue.episodeDay, {
          min: 11,
          max: 20,
        }) ||
        rangesAreEqual(changes.filters.currentValue.episodeDay, {
          min: 21,
          max: 30,
        }) ||
        rangesAreEqual(changes.filters.currentValue.episodeDay, {
          min: 31,
          max: 40,
        }) ||
        rangesAreEqual(changes.filters.currentValue.episodeDay, {
          min: 41,
          max: 50,
        }) ||
        rangesAreEqual(changes.filters?.currentValue?.episodeDay, {
          min: 51,
          max: 60,
        })) &&
      [
        LupaVisitCompletionStatus.Complete,
        LupaVisitCompletionStatus.AtRisk,
        LupaVisitCompletionStatus.Incomplete,
      ].includes(changes.filters?.currentValue?.lupaVisitCompletionStatuses[0])
    ) {
      this.activeFilterSelection = {
        episodeDayRange: changes.filters.currentValue.episodeDay,
        completionStatus:
          changes.filters.currentValue.lupaVisitCompletionStatuses[0],
        period: changes.filters.currentValue.episodeDay.max <= 30 ? 1 : 2,
      };
    } else {
      this.activeFilterSelection = undefined;
    }

    // Reload or redraw colors as needed
    if (needsReload) {
      setTimeout(this.loadChartData, 0);
    } else {
      setTimeout(this.updateColors, 0);
    }
  }

  formatXAxisLabel = (value: number) => {
    if (value < 0 || value >= this.yAxisMax) {
      return null;
    }
    return value.toFixed(0);
  };

  formatTooltipYValue = (value: number) => value.toFixed(0);

  formatTooltipLabel = (
    value: string,
    {
      seriesIndex,
      dataPointIndex,
      w: {
        config: { series },
      },
    }: any
  ) => {
    const { period } = series[seriesIndex].data[dataPointIndex]
      .meta as LupaRiskChartSelection;
    return `Period ${period}, Days ${value}`;
  };

  handleSelect = (
    e: Event,
    chartContext: any,
    {
      seriesIndex,
      dataPointIndex,
      w: {
        config: { series },
      },
    }: any
  ) => {
    this.wasClicked = true;
    const selection = series[seriesIndex].data[dataPointIndex]
      .meta as LupaRiskChartSelection;
    if (
      this.activeFilterSelection &&
      rangesAreEqual(
        this.activeFilterSelection.episodeDayRange,
        selection.episodeDayRange
      ) &&
      this.activeFilterSelection.completionStatus === selection.completionStatus
    ) {
      this.selected.emit(null);
    } else {
      this.selected.emit(selection);
    }
  };

  private getSeriesWithUpdatedColors = (
    series: ApexAxisChartSeries
  ): ApexAxisChartSeries => {
    return series.map((s) => {
      s.data.forEach((d: any) => {
        // We only care about bar color if there's nonzero y data, making the bar visible
        if (d.y) {
          [d.fillColor, d.strokeColor] = this.getBarColors(d.meta);
        }
      });
      return s;
    });
  };

  private updateColors = () => {
    this.firstPeriodSeries = this.getSeriesWithUpdatedColors(
      this.firstPeriodSeries
    );
    this.secondPeriodSeries = this.getSeriesWithUpdatedColors(
      this.secondPeriodSeries
    );
  };

  private loadChartData = async () => {
    if (!this.filters?.organizationalUnitIDs.length) {
      return;
    }

    this.isLoading = true;

    try {
      const { result } = await lastValueFrom(
        this.populationService.getLupaRiskChartData(
          // @ts-ignore
          omit(this.filters)
        )
      );
      // NOTE: We coerce 0 to null so that no data point is shown if there are no patients in a given bucket.
      // Build first period series
      this.firstPeriodSeries = LupaRiskChartCardComponent.buildSeries(
        1,
        result
      );

      // Build second period series
      this.secondPeriodSeries = LupaRiskChartCardComponent.buildSeries(
        2,
        result
      );

      // Update colors of bars based on current filters
      this.updateColors();

      // Compute max for y axis labels
      const max = Object.values(result).reduce(
        (currentMax, { completeCount, incompleteCount }) =>
          Math.max(currentMax, completeCount + incompleteCount),
        0
      );
      const adjustedMax =
        max + (this.yAxisTickCount - 3 - (max % (this.yAxisTickCount - 3)));
      const interval = adjustedMax / (this.yAxisTickCount - 3);
      this.yAxisMax = adjustedMax + interval;
      this.yAxisMin = -interval;
    } finally {
      this.isLoading = false;
      // required to detect change and refresh the graph
      this.changeDetectorRefs.detectChanges();
    }
  };

  private getBarColors = ({
    completionStatus,
    episodeDayRange,
  }: LupaRiskChartSelection): [string, string] => {
    let fillColor: string;
    let strokeColor = 'transparent';

    switch (completionStatus) {
      case LupaVisitCompletionStatus.AtRisk:
        fillColor = '#EC5756';
        break;
      case LupaVisitCompletionStatus.Incomplete:
        fillColor = '#F5C000';
        break;
      case LupaVisitCompletionStatus.Complete:
        fillColor = '#BDBDBD';
        break;
      default:
        throw new Error(`invalid lupa status ${completionStatus}`);
    }
    if (this.activeFilterSelection) {
      if (
        this.activeFilterSelection.completionStatus === completionStatus &&
        rangesAreEqual(
          this.activeFilterSelection.episodeDayRange,
          episodeDayRange
        )
      ) {
        strokeColor = '#2F80ED';
        fillColor += 'FF';
      } else {
        fillColor += '80'; // 50% alpha channel value
      }
    }
    return [fillColor, strokeColor];
  };

  private static buildSeries(
    period: number,
    data: LupaRiskChartData
  ): ApexAxisChartSeries {
    const first = period === 1 ? data['0'] : data['30'];
    const second = period === 1 ? data['10'] : data['40'];
    const third = period === 1 ? data['20'] : data['50'];

    const dayOffset = (period - 1) * 30;

    return [
      {
        name: 'Completed',
        data: [
          {
            x: '21-30',
            y: third.completeCount || null,
            meta: {
              period,
              completionStatus: LupaVisitCompletionStatus.Complete,
              episodeDayRange: { min: dayOffset + 21, max: dayOffset + 30 },
            },
          },
          {
            x: '11-20',
            y: second.completeCount || null,
            meta: {
              period,
              completionStatus: LupaVisitCompletionStatus.Complete,
              episodeDayRange: { min: dayOffset + 11, max: dayOffset + 20 },
            },
          },
          {
            x: '1-10',
            y: first.completeCount || null,
            meta: {
              period,
              completionStatus: LupaVisitCompletionStatus.Complete,
              episodeDayRange: { min: dayOffset + 1, max: dayOffset + 10 },
            },
          },
        ],
      },
      {
        name: 'Not Completed',
        data: [
          {
            x: '21-30',
            y: null,
          },
          {
            x: '11-20',
            y: second.incompleteCount || null,
            meta: {
              period,
              completionStatus: LupaVisitCompletionStatus.Incomplete,
              episodeDayRange: { min: dayOffset + 11, max: dayOffset + 20 },
            },
          },
          {
            x: '1-10',
            y: first.incompleteCount || null,
            meta: {
              period,
              completionStatus: LupaVisitCompletionStatus.Incomplete,
              episodeDayRange: { min: dayOffset + 1, max: dayOffset + 10 },
            },
          },
        ],
      },
      {
        name: 'At Risk',
        data: [
          {
            x: '21-30',
            y: third.incompleteCount || null,
            meta: {
              period,
              completionStatus: LupaVisitCompletionStatus.AtRisk,
              episodeDayRange: { min: dayOffset + 21, max: dayOffset + 30 },
            },
          },
          {
            x: '11-20',
            y: null,
          },
          {
            x: '1-10',
            y: null,
          },
        ],
      },
    ];
  }
}
