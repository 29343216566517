import { Component, Input, OnDestroy, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { RangeValue } from '../../../models/Range';
import { map } from 'rxjs/operators';
import { isNonNullable } from '../../../utils/general.utils';

@UntilDestroy()
@Component({
  selector: 'range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RangeInputComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => RangeInputComponent),
    },
  ],
})
export class RangeInputComponent
  implements OnDestroy, ControlValueAccessor, Validator {
  @Input() min?: number;
  @Input() max?: number;
  @Input() minLabel: string = 'Min';
  @Input() maxLabel: string = 'Max';

  touched = false;

  private onChangeSubs: Subscription[] = [];
  private onTouched = () => {};

  public itemControl: FormControl = new FormControl();

  public formGroup: FormGroup = this.formBuilder.group({
    min: [null, []],
    max: [null, []],
  });

  constructor(private formBuilder: FormBuilder) {}

  ngOnDestroy() {
    for (const sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  /***** ControlValueAccessor methods ******/
  writeValue(value?: RangeValue | null) {
    this.formGroup.setValue(value ?? { min: null, max: null }, {
      emitEvent: false,
    });
  }

  registerOnChange(onChange: any) {
    const sub = this.formGroup.valueChanges
      .pipe(
        map((v) => (isNonNullable(v.min) || isNonNullable(v.max) ? v : null))
      )
      .subscribe(onChange);
    this.onChangeSubs.push(sub);
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  /****** Validator methods ******/
  validate(control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};
    if (isNonNullable(this.min)) {
      if (isNonNullable(this.formGroup.value.min)) {
        if (this.formGroup.value.min < this.min) {
          errors.min = {
            min: this.min,
            actual: this.formGroup.value.min,
            field: 'min',
            message: `cannot be less than ${this.min}`,
          };
        }
      }
      if (isNonNullable(this.formGroup.value.max)) {
        if (this.formGroup.value.max < this.min) {
          errors.min = {
            min: this.min,
            actual: this.formGroup.value.min,
            field: 'max',
            message: `cannot not be less than ${this.min}`,
          };
        }
      }
    }

    if (isNonNullable(this.max)) {
      if (isNonNullable(this.formGroup.value.min)) {
        if (this.formGroup.value.min > this.max) {
          errors.max = {
            max: this.max,
            actual: this.formGroup.value.max,
            field: 'min',
            message: `cannot be greater than ${this.max}`,
          };
        }
      }
      if (isNonNullable(this.formGroup.value.max)) {
        if (this.formGroup.value.max > this.max) {
          errors.max = {
            max: this.max,
            actual: this.formGroup.value.max,
            field: 'max',
            message: `cannot be greater than ${this.max}`,
          };
        }
      }
    }

    // Ensure the range is valid
    if (
      isNonNullable(this.formGroup.value.min) &&
      isNonNullable(this.formGroup.value.max)
    ) {
      if (this.formGroup.value.min > this.formGroup.value.max) {
        errors.invalidRange = {};
      }
    }

    return Object.keys(errors).length ? errors : null;
  }
}
