import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PopulationComponent } from './population.component';

export const populationRoutes: Routes = [
  {
    path: '',
    component: PopulationComponent,
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(populationRoutes)],
  exports: [RouterModule],
})
export class PopulationRoutingModule {}
