import { Component, Input, OnInit } from '@angular/core';
import { ComorbidityLevel } from '../../../models/ComorbidityLevel';

@Component({
  selector: 'population-comorbidity-cell',
  templateUrl: './comorbidity-cell.component.html',
  styleUrls: ['./comorbidity-cell.component.scss'],
})
export class ComorbidityCellComponent implements OnInit {
  @Input() comorbidityLevel: ComorbidityLevel = ComorbidityLevel.None;
  @Input() comorbidityGroups: string = '';

  constructor() {}

  ngOnInit(): void {}

  removeUnderscores(value: string): string {
    return value?.replaceAll('_', '');
  }
}
