<div class="container">
  <!-- Progress bar -->
  <div [style.margin]="'-24px -32px 1.5rem -32px'" [style.height]="'4px'">
    <mat-progress-bar
      data-test-id="loading-indicator"
      *ngIf="(dataSource.isLoading$ | async) || !(organizationalUnit$ | async)"
      mode="indeterminate"
      color="accent"
    >
    </mat-progress-bar>
  </div>
  <div class="heading d-flex justify-content-between align-items-center mb-3">
    <button
      *ngIf="searchMode"
      data-test-id="cancel-search-button"
      atlas-icon-button
      aria-label="Close search"
      color="accent"
      (click)="toggleSearchMode()"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h2 class="mb-0">{{ title }}</h2>
    <!-- spacer -->
    <div class="flex-grow-1"></div>
    <button
      data-test-id="search-button"
      *ngIf="!this.searchMode"
      atlas-icon-button
      aria-label="Search organizational units"
      color="accent"
      (click)="toggleSearchMode()"
    >
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="searchMode">
    <form [formGroup]="searchForm" class="mt-2">
      <atlas-input
        data-test-id="search-input"
        formControlName="search"
        [style.maxWidth]="'400px'"
        floatLabel="auto"
      >
        <span label>
          <span class="d-flex align-items-center">
            <mat-icon class="search-field-icon">search</mat-icon>
            <span>Search</span>
          </span>
        </span>
      </atlas-input>
    </form>

    <!-- Placeholder -->
    <i *ngIf="!searchForm.value.search" class="flex-grow-1">
      Start typing an organizational unit name to see results
    </i>

    <cdk-virtual-scroll-viewport
      #scroller
      *ngIf="!!searchForm.value.search"
      class="content"
      [itemSize]="LIST_ITEM_HEIGHT"
    >
      <mat-selection-list #ouList [multiple]="false">
        <ng-container
          *cdkVirtualFor="
            let ou of (dataSource.items$ | async)?.filter(
              canSelectOrganizationalUnit
            )
          "
        >
          <mat-list-option
            [value]="ou"
            [disabled]="!canSelectOrganizationalUnit(ou)"
            [class.list-option]="true"
          >
            <div class="ou-name">{{ ou.name }}</div>
            <div class="ou-parent">{{ ou.parentName }}</div>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <ng-container *ngIf="!searchMode">
    <!-- Current OU name an back button -->
    <div
      *ngIf="canLevelUp$ | async"
      class="d-flex align-items-center"
      [style.height]="'40px'"
    >
      <button
        data-test-id="uplevel-button"
        atlas-icon-button
        aria-label="Up a level"
        color="accent"
        (click)="levelUp()"
      >
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <div class="ml-1 mt-1 font-weight-bold">
        {{ (organizationalUnit$ | async)?.name }}
      </div>
    </div>

    <mat-divider class="mt-3"></mat-divider>

    <!-- List -->
    <cdk-virtual-scroll-viewport
      #scroller
      [itemSize]="LIST_ITEM_HEIGHT"
      class="content"
    >
      <mat-selection-list #ouList [multiple]="false">
        <!-- Manually added option to select top level -->
        <ng-container
          *ngIf="(organizationalUnit$ | async) && !(canLevelUp$ | async)"
        >
          <mat-list-option
            [value]="organizationalUnit$ | async"
            [class.list-option]="true"
          >
            <div
              class="d-flex justify-content-between align-items-center item-content"
            >
              <div class="ou-name">
                {{ (organizationalUnit$ | async)?.name }}
              </div>
            </div>
          </mat-list-option>
          <mat-divider class="mt-2"></mat-divider>
        </ng-container>

        <ng-container
          *cdkVirtualFor="
            let ou of (dataSource.items$ | async)?.sort(ouListComparator)
          "
        >
          <mat-list-option
            [value]="ou"
            [class.unselectable]="!canSelectOrganizationalUnit(ou)"
            [class.list-option]="true"
          >
            <div
              class="d-flex justify-content-between align-items-center item-content"
            >
              <div class="ou-name">{{ ou.name }}</div>
              <button
                *ngIf="canSelectOrganizationalUnit(ou)"
                atlas-icon-button
                attr.aria-label="View organizational units under {{ ou.name }}"
                (click)="drillDown(ou)"
                class="drill-down-button"
              >
                <mat-icon>arrow_forward_ios</mat-icon>
              </button>
            </div>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <!-- Selection error -->
  <div>
    <mat-error *ngIf="!!getSelectedOrganizationalUnit() && !canSubmit()">
      Cannot {{ verb }} {{ getSelectedOrganizationalUnit()!.name }}
    </mat-error>
  </div>

  <!-- Buttons -->
  <div class="align-self-end">
    <button
      data-test-id="submit-button"
      atlas-button
      color="accent"
      [disabled]="!canSubmit()"
      (click)="submit()"
    >
      {{ submitLabel | uppercase }}
    </button>
    <button atlas-button atlas-dialog-close>CANCEL</button>
  </div>
</div>
