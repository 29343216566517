import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { isNonNullable } from './general.utils';

export const getDisplayError = (errorResponse: HttpErrorResponse): string => {
  const errorMessages = errorResponse.error?.errors;
  if (!Array.isArray(errorMessages)) {
    return 'An unknown error occurred';
  }
  if (errorMessages.length === 1) {
    return errorMessages[0];
  }
  let displayError = 'There were a couple problems:';
  for (const msg of errorMessages) {
    displayError += `\n\t\u2022 ${msg}`;
  }
  return displayError;
};

export const getHttpParamsFromObject = (req: Object): HttpParams => {
  let params = new HttpParams();
  for (const [property, value] of Object.entries(req)) {
    if (isNonNullable(value)) {
      if (Array.isArray(value)) {
        for (const el of value) {
          params = params.append(property, el.toString());
        }
      } else {
        params = params.append(property, value.toString());
      }
    }
  }
  return params;
};

export const appendArray = (
  params: HttpParams,
  key: string,
  values: string[]
) => {
  let newParams = params;
  for (const v of values) {
    newParams = newParams.append(key, v);
  }
  return newParams;
};
