<div class="hospitalization-risk-cell">
  <ng-container
    *ngIf="
      hospitalizationRiskStratification !== undefined &&
      hospitalizationRisk !== undefined
    "
  >
    <div
      class="fill"
      [style.flex-basis]="hospitalizationRisk * 100 + '%'"
      [ngClass]="['fill', hospitalizationRiskStratification]"
    ></div>
    <div class="empty"></div>
  </ng-container>
</div>
