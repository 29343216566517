import { Inject, Injectable } from '@angular/core';
import { AuthorizedUser, UserMetadata } from '../models/AuthorizedUser';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { first, map, shareReplay, filter } from 'rxjs/operators';
import { OrganizationalUnitService } from '../core/services/organizational-unit.service';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth, UserClaims } from '@okta/okta-auth-js';

const WHOAMI_URL = `${environment.apiURL}/auth/whoami`;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static instance?: AuthService;

  private static currentUserSubject = new ReplaySubject<AuthorizedUser>(1);
  public static currentUser$ = AuthService.currentUserSubject.asObservable();
  public currentUser$ = AuthService.currentUserSubject.asObservable();

  private static authToken: ReplaySubject<string> = new ReplaySubject(1);
  public static authToken$ = AuthService.authToken.asObservable();

  private _currentUser: AuthorizedUser | null = null;

  constructor(
    private authState: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private http: HttpClient,
    private organizationalUnitService: OrganizationalUnitService
  ) {
    if (AuthService.instance) {
      console.error('only one instance of AuthService should exist');
    }
    AuthService.instance = this;

    authState.authState$.subscribe((state) => this.handleUserDataChange(state));

    this.authState.authState$
      .pipe(
        filter((state) => !!state.accessToken?.accessToken),
        map((state) => 'Bearer ' + state.accessToken?.accessToken)
      )
      .subscribe(AuthService.authToken);
  }

  public login = () => {
    this.oktaAuth.signInWithRedirect();
  };

  public logout = (): void => {
    this.oktaAuth.signOut().catch((e) => {
      console.error('error logging user out', e);
    });
  };

  private handleUserDataChange = async (state: AuthState) => {
    if (state?.isAuthenticated && state.idToken) {
      const user = AuthorizedUser.fromTokenClaims(
        state.idToken?.claims,
        state.accessToken?.claims
      );

      // If the subject changed, fetch data from whoami
      if (state?.idToken?.claims?.sub !== this._currentUser?.id) {
        user.metadata = await lastValueFrom(
          this.http.get<UserMetadata>(WHOAMI_URL)
        );
        // Add lazy-loadable organizational unit onto user
        user.metadata.organizationalUnit$ = this.organizationalUnitService
          .getById(user.metadata.organizationalUnitId)
          .pipe(
            first(),
            map((res) => res.result),
            // Cache
            shareReplay(1)
          );
      } else {
        user.metadata = this._currentUser!.metadata;
      }
      this._currentUser = user;
      AuthService.currentUserSubject.next(user);
    }
  };
}
