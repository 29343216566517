import { Component, Input, OnInit } from '@angular/core';
import {
  UtilizationLevel,
  UtilizationLevelLabels,
} from '../../../models/UtilizationLevel';

@Component({
  selector: 'population-visit-utilization-cell',
  templateUrl: './visit-utilization-cell.component.html',
  styleUrls: ['./visit-utilization-cell.component.scss'],
})
export class VisitUtilizationCellComponent implements OnInit {
  @Input() visitUtilizationLevel!: UtilizationLevel;

  overOrUnder = false;
  label = '';

  constructor() {}

  ngOnInit(): void {
    if (
      this.visitUtilizationLevel &&
      this.visitUtilizationLevel !== UtilizationLevel.None
    ) {
      this.overOrUnder =
        this.visitUtilizationLevel === UtilizationLevel.Over ||
        this.visitUtilizationLevel === UtilizationLevel.Under;

      this.label = UtilizationLevelLabels[this.visitUtilizationLevel];
    } else {
      this.label = '-';
    }
  }
}
