import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  HospitalizationRiskStratification,
  HospitalizationRiskStratificationLabels,
} from '../../../models/HospitalizationRisk';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { PopulationFilters, PopulationService } from '../../population.service';
import { omit } from '../../../utils/object.utils';
import { lastValueFrom } from 'rxjs';

interface CellMeta {
  stratification: HospitalizationRiskStratification;
}

@Component({
  selector: 'population-hospitalization-risk-chart-card',
  templateUrl: './hospitalization-risk-chart-card.component.html',
  styleUrls: ['./hospitalization-risk-chart-card.component.scss'],
})
export class HospitalizationRiskChartCardComponent implements OnChanges {
  private static colors: Record<HospitalizationRiskStratification, string> = {
    [HospitalizationRiskStratification.Low]: '#27ae60',
    [HospitalizationRiskStratification.Guarded]: '#fdc400',
    [HospitalizationRiskStratification.Elevated]: '#fb9a00',
    [HospitalizationRiskStratification.High]: '#f44236',
    [HospitalizationRiskStratification.VeryHigh]: '#ba1509',
  };

  private static legend = [
    HospitalizationRiskStratification.VeryHigh,
    HospitalizationRiskStratification.High,
    HospitalizationRiskStratification.Elevated,
    HospitalizationRiskStratification.Guarded,
    HospitalizationRiskStratification.Low,
  ];

  private wasClicked: boolean = false;

  @Input() filters: PopulationFilters | null = null;

  @Output()
  levelSelected: EventEmitter<HospitalizationRiskStratification | null> = new EventEmitter<HospitalizationRiskStratification | null>();

  series: ApexAxisChartSeries = [];
  legendLabels: string[] = HospitalizationRiskChartCardComponent.legend.map(
    (stratification) => HospitalizationRiskStratificationLabels[stratification]
  );
  legendColors: string[] = HospitalizationRiskChartCardComponent.legend.map(
    (s) => HospitalizationRiskChartCardComponent.colors[s]
  );

  isLoading: boolean = true;

  private activeFilterSelection?: HospitalizationRiskStratification;

  constructor(
    private populationService: PopulationService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.filters?.currentValue ||
      changes.filters.previousValue === changes.filters.currentValue
    ) {
      return;
    }

    let needsReload = false;

    for (const filter of Object.keys(changes.filters.currentValue)) {
      if (!this.wasClicked) {
        if (
          changes.filters.currentValue[filter] !==
          changes.filters.previousValue?.[filter]
        ) {
          needsReload = true;
          break;
        }
      }
    }

    this.wasClicked = false;

    // Update active filter selection
    if (
      changes.filters.currentValue.hospitalizationRiskStratifications
        ?.length === 1
    ) {
      this.activeFilterSelection =
        changes.filters.currentValue.hospitalizationRiskStratifications[0];
    } else {
      this.activeFilterSelection = undefined;
    }

    // Reload or redraw colors as needed
    if (needsReload) {
      setTimeout(this.loadChartData, 0);
    } else {
      setTimeout(this.updateColors, 0);
    }
  }

  private updateColors = () => {
    this.series = this.series.map((s) => {
      s.data.forEach((d: any) => {
        // We only care about bar color if there's nonzero y data, making the bar visible
        if (d.y) {
          const { stratification } = d.meta as CellMeta;
          let color =
            HospitalizationRiskChartCardComponent.colors[stratification];
          if (this.filters?.hospitalizationRiskStratifications?.length === 1) {
            if (this.activeFilterSelection === stratification) {
              d.fillColor = color + 'FF';
              d.strokeColor = '#2F80ED';
            } else {
              d.fillColor = color + '80'; // Adds 50% opacity
              d.strokeColor = 'white';
            }
          } else {
            d.fillColor = color + 'FF'; // Full opacity
            d.strokeColor = 'white';
          }
        }
      });
      return s;
    });
  };

  private loadChartData = async () => {
    if (!this.filters?.organizationalUnitIDs.length) {
      return;
    }

    this.isLoading = true;

    try {
      const { result } = await lastValueFrom(
        this.populationService.getHospitalizationRiskChartData(
          // @ts-ignore
          omit(this.filters)
        )
      );

      const dataPoints = [
        {
          x: HospitalizationRiskStratification.Low,
          y: result.lowCount,
          meta: { stratification: HospitalizationRiskStratification.Low },
        },
        {
          x: HospitalizationRiskStratification.Guarded,
          y: result.guardedCount,
          meta: { stratification: HospitalizationRiskStratification.Guarded },
        },
        {
          x: HospitalizationRiskStratification.Elevated,
          y: result.elevatedCount,
          meta: { stratification: HospitalizationRiskStratification.Elevated },
        },
        {
          x: HospitalizationRiskStratification.High,
          y: result.highCount,
          meta: { stratification: HospitalizationRiskStratification.High },
        },
        {
          x: HospitalizationRiskStratification.VeryHigh,
          y: result.veryHighCount,
          meta: { stratification: HospitalizationRiskStratification.VeryHigh },
        },
      ];
      this.series = [
        {
          // Sort largest to smallest
          data: dataPoints.sort((a, b) => b.y - a.y),
        },
      ];

      // Update colors of bars based on current filters
      this.updateColors();
    } finally {
      this.isLoading = false;
      // required to detect change and refresh the graph
      this.changeDetectorRefs.detectChanges();
    }
  };

  getTooltipStratificationLabel = (
    _: string,
    { dataPointIndex }: { dataPointIndex: number }
  ) => {
    const stratification = (this.series[0].data[dataPointIndex]! as any).x;
    return HospitalizationRiskStratificationLabels[
      stratification as HospitalizationRiskStratification
    ];
  };

  formatLabel = (
    stratification: HospitalizationRiskStratification,
    { dataPointIndex }: { dataPointIndex: number }
  ) =>
    (this.series[0].data[dataPointIndex]! as { y: number }).y.toLocaleString();

  handleSelect = (
    e: Event,
    chartContext: any,
    {
      seriesIndex,
      dataPointIndex,
      w: {
        config: { series },
      },
    }: any
  ) => {
    this.wasClicked = true;
    const selection = series[seriesIndex].data[dataPointIndex].meta as CellMeta;
    this.levelSelected.emit(
      selection.stratification === this.activeFilterSelection
        ? null
        : selection.stratification
    );
  };
}
