<div class="population-view-container">
  <!-- Title and 'last updated at' heading -->
  <div
    class="population-view-header d-flex align-items-center justify-content-between"
    data-test-id="heading"
  >
    <div>Population View</div>
    <div class="d-flex flex-column align-items-end">
      <!-- TODO: add this back when we know what to do with it -->
      <!-- <div class="last-updated">Last Updated</div>
      <div><strong>Today, 10:23 AM</strong></div> -->
    </div>
  </div>

  <mat-sidenav-container class="filter-drawer-container">
    <!-- FILTER DRAWER -->
    <mat-sidenav #filterDrawer mode="push" class="filter-drawer">
      <population-filter-drawer
        (close)="filterDrawer.close()"
      ></population-filter-drawer>
    </mat-sidenav>
    <!-- CONTENT -->
    <mat-sidenav-content class="content">
      <!-- Chart cards -->
      <div *ngIf="showTiles$ | async" class="row gx-3 gy-3">
        <div class="col-sm-12 col-md-6 col-lg-4">
          <population-hospitalization-risk-chart-card
            data-test-id="hosp-risk-chart"
            [filters]="filters$ | async"
            (levelSelected)="handleHospitalizationRiskChartSelection($event)"
          ></population-hospitalization-risk-chart-card>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
          <population-visit-utilization-chart-card
            data-test-id="visit-utilization-chart"
            [filters]="filters$ | async"
            (levelSelected)="handleVisitUtilizationChartSelection($event)"
          ></population-visit-utilization-chart-card>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
          <population-lupa-risk-chart-card
            data-test-id="lupa-risk-chart"
            [filters]="filters$ | async"
            (selected)="handleLupaRiskChartSelection($event)"
          ></population-lupa-risk-chart-card>
        </div>
      </div>

      <!-- Filter button and pagination -->
      <div class="filter-pagination-container" data-test-id="heading">
        <!-- Need to display empty div if filter not enabled to preserve flex formatting -->
        <ng-container [ngSwitch]="showFilters$ | async">
          <!-- enabled case -->
          <div
            *ngSwitchCase="true"
            class="d-flex align-items-center flex-grow-1"
          >
            <button
              atlas-button
              (click)="filterDrawer.toggle()"
              class="filter-button"
              data-test-id="filter-drawer-trigger"
            >
              <span>FILTER</span>
              <mat-icon>filter_list</mat-icon>
            </button>
            <filter-chips data-test-id="filter-chips-container"></filter-chips>
          </div>

          <!-- disabled case -->
          <div *ngSwitchCase="false"></div>
        </ng-container>

        <button
          data-test-id="export-csv-button"
          atlas-raised-button
          class="me-2 export-csv-button"
          (click)="downloadCSV()"
          [disabled]="
            (dataSource.totalCount$ | async) === 0 || !hasCSVDownloaded
          "
        >
          <span *ngIf="hasCSVDownloaded">EXPORT CSV</span>
          <span *ngIf="!hasCSVDownloaded">DOWNLOADING</span>
        </button>

        <mat-paginator
          class="paginator"
          [length]="dataSource.totalCount$ | async"
          [pageSize]="20"
          hidePageSize
          showFirstLastButtons
        >
        </mat-paginator>
      </div>

      <!-- Loading spinner -->
      <div
        data-test-id="loading-indicator"
        class="spinner-container"
        *ngIf="dataSource.isLoading$ | async"
      >
        <atlas-spinner aria-label="Loading users"></atlas-spinner>
      </div>

      <!-- Table -->
      <div
        #tableContainer
        data-test-id="population-table"
        class="table-container"
      >
        <mat-table
          class="population-table"
          [dataSource]="dataSource"
          matSort
          [matSortActive]="(gridService.sortColumnId$ | async) ?? 'hospitalizationRiskScaled'"
          [matSortDirection]="(gridService.sortDirection$ | async) ?? 'desc'"
          matSortDisableClear="true"
          #sortPatients="matSort"
        >
          <ng-container matColumnDef="alerts">
            <mat-header-cell
              *matHeaderCellDef
              [style.min-width.px]="columns.alerts.minWidth"
              [style.max-width.px]="columns.alerts.maxWidth"
            >
              Alerts
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.alerts.minWidth"
              [style.max-width.px]="columns.alerts.maxWidth"
            >
              <alerts-cell [patient]="patient"></alerts-cell>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="lastName"
              [style.min-width.px]="columns.lastName.minWidth"
              [style.max-width.px]="columns.lastName.maxWidth"
            >
              Last<br />Name
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.lastName.minWidth"
              [style.max-width.px]="columns.lastName.maxWidth"
            >
              <span class="single-line">
                {{ patient.lastName | titlecase }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container
            matColumnDef="firstName"
            [style.min-width.px]="columns.firstName.minWidth"
            [style.max-width.px]="columns.firstName.maxWidth"
          >
            <mat-header-cell *matHeaderCellDef mat-sort-header="firstName">
              First<br />Name
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.firstName.minWidth"
              [style.max-width.px]="columns.firstName.maxWidth"
            >
              <span class="single-line">
                {{ patient.firstName | titlecase }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="hospitalizationRisk">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="hospitalizationRiskScaled"
              start="desc"
              [style.min-width.px]="columns.hospitalizationRisk.minWidth"
              [style.max-width.px]="columns.hospitalizationRisk.maxWidth"
            >
              Hospitalization<br />Risk
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.hospitalizationRisk.minWidth"
              [style.max-width.px]="columns.hospitalizationRisk.maxWidth"
            >
              <hospitalization-risk-cell
                [hospitalizationRisk]="patient.hospitalizationRiskScaled"
                [hospitalizationRiskStratification]="
                  patient.hospitalizationRiskStratification
                "
              >
              </hospitalization-risk-cell>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="primaryDx">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="primaryDiagnosisCode"
              [style.min-width.px]="columns.primaryDx.minWidth"
              [style.max-width.px]="columns.primaryDx.maxWidth"
            >
              Primary<br />DX
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.primaryDx.minWidth"
              [style.max-width.px]="columns.primaryDx.maxWidth"
            >
              <span class="single-line">
                {{
                  getDiagnosisText(
                    patient.primaryDiagnosisCode,
                    patient.primaryDiagnosisDescription
                  )
                }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="comorbidities">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="comorbidityLevel"
              start="desc"
              [style.min-width.px]="columns.comorbidities.minWidth"
              [style.max-width.px]="columns.comorbidities.maxWidth"
            >
              Comorbidities
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.comorbidities.minWidth"
              [style.max-width.px]="columns.comorbidities.maxWidth"
            >
              <population-comorbidity-cell
                [comorbidityLevel]="patient.comorbidityLevel"
                [comorbidityGroups]="patient.comorbidityGroups"
              ></population-comorbidity-cell>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="hospiceSuitability">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="hospiceSuitability"
              start="desc"
              [style.min-width.px]="columns.hospiceSuitability.minWidth"
              [style.max-width.px]="columns.hospiceSuitability.maxWidth"
            >
              Hospice<br />Suitability
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.hospiceSuitability.minWidth"
              [style.max-width.px]="columns.hospiceSuitability.maxWidth"
            >
              <hospice-suitability-cell
                [hospiceSuitabilityStratification]="
                  patient.hospiceSuitabilityStratification
                "
              ></hospice-suitability-cell>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="adlImprovement">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="adlComposite"
              [style.min-width.px]="columns.adlImprovement.minWidth"
              [style.max-width.px]="columns.adlImprovement.maxWidth"
            >
              Improvement<br />in ADLs
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.adlImprovement.minWidth"
              [style.max-width.px]="columns.adlImprovement.maxWidth"
            >
              <adl-improvement-cell
                [adlCompositeStratification]="
                  patient.adlCompositeStratification
                "
              ></adl-improvement-cell>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="plannedVisits">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="totalVisitCount"
              atlasTooltip="This column shows the total number of planned visits for this payment period."
              [style.min-width.px]="columns.plannedVisits.minWidth"
              [style.max-width.px]="columns.plannedVisits.maxWidth"
            >
              Planned<br />Visits
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.plannedVisits.minWidth"
              [style.max-width.px]="columns.plannedVisits.maxWidth"
            >
              <span class="numeric sortable">
                {{ patient.totalVisitCount }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="plannedInPersonVisits">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="totalInPersonVisitCount"
              atlasTooltip="This column shows the total number of planned in-person visits for this payment period."
              [style.min-width.px]="columns.plannedInPersonVisits.minWidth"
              [style.max-width.px]="columns.plannedInPersonVisits.maxWidth"
            >
              Planned<br />Visits (In-Person)
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.plannedInPersonVisits.minWidth"
              [style.max-width.px]="columns.plannedInPersonVisits.maxWidth"
            >
              <span class="numeric sortable">
                {{ patient.totalInPersonVisitCount }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="utilizationAssessment">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="visitUtilizationLevel"
              start="desc"
              [style.min-width.px]="columns.utilizationAssessment.minWidth"
              [style.max-width.px]="columns.utilizationAssessment.maxWidth"
            >
              Utilization<br />Assessment
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.utilizationAssessment.minWidth"
              [style.max-width.px]="columns.utilizationAssessment.maxWidth"
            >
              <population-visit-utilization-cell
                [visitUtilizationLevel]="patient.visitUtilizationLevel"
              ></population-visit-utilization-cell>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="plannedVirtualVisits">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="totalVirtualVisitCount"
              atlasTooltip="This column shows the total number of planned virtual visits for this payment period."
              [style.min-width.px]="columns.plannedVirtualVisits.minWidth"
              [style.max-width.px]="columns.plannedVirtualVisits.maxWidth"
            >
              Planned<br />Visits (Virtual)
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.plannedVirtualVisits.minWidth"
              [style.max-width.px]="columns.plannedVirtualVisits.maxWidth"
            >
              <span class="numeric sortable">
                {{ patient.totalVirtualVisitCount }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="lupaRisk">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header="lupaRisk"
              start="desc"
              [style.min-width.px]="columns.lupaRisk.minWidth"
              [style.max-width.px]="columns.lupaRisk.maxWidth"
            >
              LUPA<br />Risk
            </mat-header-cell>
            <mat-cell
              *matCellDef="let patient"
              [style.min-width.px]="columns.lupaRisk.minWidth"
              [style.max-width.px]="columns.lupaRisk.maxWidth"
            >
              <population-lupa-risk-cell
                [patient]="patient"
              ></population-lupa-risk-cell>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let patient; columns: displayedColumns"
            (click)="navigateToPatient(patient.patientId)"
            [style.cursor]="(allowPatientNavigation$ | async) ? 'pointer' : ''"
          ></mat-row>
        </mat-table>

        <!-- Empty state -->
        <div
          *ngIf="
            !(dataSource.isLoading$ | async) &&
            (dataSource.totalCount$ | async) === 0
          "
          class="empty-state"
        >
          There are currently no patients to display.
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
