import { ApiErrorBannerComponent } from './api-error-banner/api-error-banner.component';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AtlasBannersModule } from '@wellsky/atlas-ui';
import { AtlasButtonModule } from '@wellsky/atlas-ui/button';

@NgModule({
  declarations: [ApiErrorBannerComponent],
  imports: [MatIconModule, AtlasBannersModule, AtlasButtonModule],
  exports: [ApiErrorBannerComponent],
})
export class AppBannersModule {}
