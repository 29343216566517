<form class="range-input" [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>{{ minLabel }}</mat-label>
    <input
      matInput
      formControlName="min"
      type="number"
      [min]="min?.toString() ?? ''"
      [max]="max?.toString() ?? ''"
      data-test-id="range-input-low"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ maxLabel }}</mat-label>
    <input
      matInput
      formControlName="max"
      type="number"
      [min]="min?.toString() ?? ''"
      [max]="max?.toString() ?? ''"
      data-test-id="range-input-high"
    />
  </mat-form-field>
</form>
