import { ApiSortDirection, PaginatedRequest } from '../models/Pagination';
import { finalize } from 'rxjs/operators';
import { PaginatedResultBaseDatasource } from '../core/paginated-base.datasource';
import { PatientReadModel } from '../models/PatientReadModel';
import { PopulationFilters, PopulationService } from './population.service';
import { ChangeDetectorRef } from '@angular/core';

export class PopulationDatasource extends PaginatedResultBaseDatasource<PatientReadModel> {
  constructor(
    private populationService: PopulationService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
    super();
  }

  loadList(filters: PopulationFilters, pagination: Partial<PaginatedRequest>) {
    const mergedPagination = {
      page: 1,
      pageSize: 40,
      sortBy: 'lastname',
      sortDirection: ApiSortDirection.Ascending,
      ...pagination,
    };
    this.isLoadingSubject.next(true);
    this.populationService
      .getList(filters, mergedPagination)
      .pipe(
        finalize(() => {
          this.isLoadingSubject.next(false);
          this.changeDetectorRefs.detectChanges();
        })
      )
      .subscribe((res) => {
        this.paginatedResponseSubject.next(res);
      });
  }
}
