export interface LupaRiskChartData {
  '0': LupaRiskAggregation;
  '10': LupaRiskAggregation;
  '20': LupaRiskAggregation;
  '30': LupaRiskAggregation;
  '40': LupaRiskAggregation;
  '50': LupaRiskAggregation;
}

export interface LupaRiskAggregation {
  completeCount: number;
  incompleteCount: number;
}

export enum LupaVisitCompletionStatus {
  AtRisk = 'atRisk',
  Incomplete = 'incomplete',
  Complete = 'complete',
  NotAtRisk = 'notAtRisk',
}

export const LupaVisitCompletionStatusLabels: Record<
  LupaVisitCompletionStatus,
  string
> = {
  [LupaVisitCompletionStatus.AtRisk]: 'At risk',
  [LupaVisitCompletionStatus.Incomplete]: 'Not completed',
  [LupaVisitCompletionStatus.Complete]: 'Completed',
  [LupaVisitCompletionStatus.NotAtRisk]: 'Not at risk',
};
