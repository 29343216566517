<atlas-card>
  <atlas-card-header>
    <atlas-card-title>LUPA Risk</atlas-card-title>
    <atlas-header-action>
      <!-- TODO Add ? icon -->
    </atlas-header-action>
  </atlas-card-header>
  <atlas-card-content>
    <div
      *ngIf="isLoading"
      class="loading-spinner"
      data-test-id="loading-indicator"
    >
      <atlas-spinner></atlas-spinner>
    </div>
    <div *ngIf="!isLoading" class="chart-container">
      <div class="legend" [style.gridArea]="'legend'">
        <div class="legend-item">
          <div class="legend-icon" [style.backgroundColor]="'#EC5756'"></div>
          At Risk
        </div>
        <div class="legend-item">
          <div class="legend-icon" [style.backgroundColor]="'#F5C000'"></div>
          Not Completed
        </div>
        <div class="legend-item">
          <div class="legend-icon" [style.backgroundColor]="'#BDBDBD'"></div>
          Completed
        </div>
      </div>
      <div
        class="chart-axis-title chart-y-axis-title"
        [style.gridArea]="'yAxisTitle'"
      >
        Payment Period Days
      </div>

      <div class="chart-y-axis-labels" [style.gridArea]="'yAxisLabels'">
        <div>21-30</div>
        <div>11-20</div>
        <div>1-10</div>
      </div>

      <!-- First period -->
      <div class="chart" [style.gridArea]="'period1Chart'">
        <ng-container
          *ngTemplateOutlet="periodChart; context: { period: 1 }"
        ></ng-container>
      </div>

      <!-- Second period -->
      <div class="chart second-chart" [style.gridArea]="'period2Chart'">
        <ng-container
          *ngTemplateOutlet="periodChart; context: { period: 2 }"
        ></ng-container>
      </div>

      <div
        class="chart-axis-title chart-x-axis-title"
        [style.gridArea]="'period1XAxisTitle'"
      >
        Patients - Period 1
      </div>
      <div
        class="chart-axis-title chart-x-axis-title"
        [style.gridArea]="'period2XAxisTitle'"
      >
        Patients - Period 2
      </div>
    </div>
  </atlas-card-content>
</atlas-card>

<!-- Template Partials -->

<!-- Chart -->
<ng-template #periodChart let-period="period">
  <apx-chart
    [series]="period === 1 ? firstPeriodSeries : secondPeriodSeries"
    [chart]="{
      height: 162,
      width: '100%',
      type: 'bar',
      stacked: true,
      toolbar: { show: false },
      animations: { enabled: false },
      events: {
        dataPointSelection: handleSelect
      }
    }"
    [plotOptions]="{
      bar: {
        horizontal: true,
        borderRadius: 0,
        barHeight: '50%'
      }
    }"
    [states]="{
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    }"
    [stroke]="{ width: 2 }"
    [title]="{ margin: 0 }"
    [fill]="{ opacity: 1 }"
    [yaxis]="{ show: false }"
    [xaxis]="{
      type: 'category',
      tickAmount: yAxisTickCount - 1,
      min: yAxisMin,
      max: yAxisMax,
      axisTicks: { show: false },
      labels: {
        formatter: $any(formatXAxisLabel),
        offsetY: -5,
        style: {
          colors: '#43505E',
          fontSize: '11px',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 300
        }
      }
    }"
    [dataLabels]="{ enabled: false }"
    [legend]="{ show: false }"
    [grid]="{
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    }"
    [tooltip]="{
      y: {
        formatter: $any(formatTooltipYValue)
      },
      x: {
        formatter: $any(formatTooltipLabel)
      }
    }"
  ></apx-chart>
</ng-template>
