import { Component, Input, OnInit } from '@angular/core';
import { getLupaRiskDetails, LupaDetails } from '../../../utils/lupa.utils';
import {
  billingPeriodEndDate,
  PatientReadModel,
} from '../../../models/PatientReadModel';

@Component({
  selector: 'population-lupa-risk-cell',
  templateUrl: './lupa-risk-cell.component.html',
  styleUrls: ['./lupa-risk-cell.component.scss'],
})
export class LupaRiskCellComponent implements OnInit {
  @Input() patient!: PatientReadModel;

  lupaDetails?: LupaDetails;

  constructor() {}

  ngOnInit(): void {
    this.lupaDetails = getLupaRiskDetails(
      this.patient.completedVisitCount,
      this.patient.lupaMinimumVisitCount,
      billingPeriodEndDate(this.patient)
    );
  }
}
