import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { PopulationFilters, PopulationService } from '../../population.service';
import {
  UtilizationLevel,
  UtilizationLevelLabels,
} from '../../../models/UtilizationLevel';
import { rangesAreEqual, RangeValue } from '../../../models/Range';
import { omit } from '../../../utils/object.utils';
import { lastValueFrom } from 'rxjs';

export interface VisitUtilizationChartSelection {
  level: UtilizationLevel;
  episodeDayRange: RangeValue;
}

@Component({
  selector: 'population-visit-utilization-chart-card',
  templateUrl: './visit-utilization-chart-card.component.html',
  styleUrls: ['./visit-utilization-chart-card.component.scss'],
})
export class VisitUtilizationChartCardComponent implements OnChanges {
  private static colors: Record<UtilizationLevel, string> = {
    [UtilizationLevel.Under]: '#EC5756',
    [UtilizationLevel.InLine]: '#BDBDBD',
    [UtilizationLevel.Over]: '#EC5756',
    [UtilizationLevel.None]: '#f44236', // Will not display
  };

  private wasClicked: boolean = false;

  @Input() filters: PopulationFilters | null = null;

  @Output()
  levelSelected: EventEmitter<VisitUtilizationChartSelection | null> = new EventEmitter<VisitUtilizationChartSelection | null>();

  series: ApexAxisChartSeries = [];
  legendLabels: string[] = [
    `${UtilizationLevelLabels[UtilizationLevel.Under]} / ${
      UtilizationLevelLabels[UtilizationLevel.Over]
    }`,
    UtilizationLevelLabels[UtilizationLevel.InLine],
  ];
  legendColors: string[] = [
    VisitUtilizationChartCardComponent.colors[UtilizationLevel.Under], // Under / Over
    VisitUtilizationChartCardComponent.colors[UtilizationLevel.InLine], // In Line
  ];

  isLoading: boolean = true;

  constructor(
    private populationService: PopulationService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.filters?.currentValue ||
      changes.filters.previousValue === changes.filters.currentValue
    ) {
      return;
    }

    let needsReload = false;

    for (const filter of Object.keys(changes.filters.currentValue)) {
      if (!this.wasClicked) {
        if (
          changes.filters.currentValue[filter] !==
          changes.filters.previousValue?.[filter]
        ) {
          needsReload = true;
          break;
        }
      }
    }

    this.wasClicked = false;

    if (needsReload) {
      setTimeout(this.loadChartData, 0);
    } else {
      setTimeout(this.updateColors, 0);
    }
  }

  private updateColors = () => {
    this.series = this.series.map((s) => {
      s.data.forEach((d: any) => {
        // We only care about bar color if there's nonzero y data, making the bar visible
        const {
          level,
          episodeDayRange,
        } = d.meta as VisitUtilizationChartSelection;
        let color = VisitUtilizationChartCardComponent.colors[level];
        if (
          this.filters?.episodeDay &&
          this.filters.visitUtilizationLevels?.length === 1 &&
          (rangesAreEqual(this.filters.episodeDay, { min: 1, max: 30 }) ||
            rangesAreEqual(this.filters.episodeDay, { min: 31, max: 60 })) &&
          [
            UtilizationLevel.Over,
            UtilizationLevel.InLine,
            UtilizationLevel.Under,
          ].includes(this.filters.visitUtilizationLevels[0])
        ) {
          if (
            rangesAreEqual(this.filters.episodeDay, episodeDayRange) &&
            this.filters.visitUtilizationLevels[0] === level
          ) {
            d.strokeColor = '#2F80ED';
            d.fillColor = color + 'FF';
          } else {
            d.fillColor = color + '80'; // Adds 50% opacity
            d.strokeColor = 'transparent';
          }
        } else {
          d.fillColor = color + 'FF';
          d.strokeColor = 'transparent';
        }
      });
      return s;
    });
  };

  private loadChartData = async () => {
    if (!this.filters?.organizationalUnitIDs.length) {
      return;
    }

    this.isLoading = true;

    try {
      const { result } = await lastValueFrom(
        this.populationService.getVisitUtilizationChartData(
          // @ts-ignore
          omit(this.filters)
        )
      );
      this.series = [
        {
          name: UtilizationLevelLabels[UtilizationLevel.Under],
          data: [
            {
              x: 'Period 1',
              y: result.periodOne.under,
              meta: {
                level: UtilizationLevel.Under,
                episodeDayRange: { min: 1, max: 30 },
              },
            },
            {
              x: 'Period 2',
              y: result.periodTwo.under,
              meta: {
                level: UtilizationLevel.Under,
                episodeDayRange: { min: 31, max: 60 },
              },
            },
          ],
        },
        {
          name: UtilizationLevelLabels[UtilizationLevel.InLine],
          data: [
            {
              x: 'Period 1',
              y: result.periodOne.inLine,
              meta: {
                level: UtilizationLevel.InLine,
                episodeDayRange: { min: 1, max: 30 },
              },
            },
            {
              x: 'Period 2',
              y: result.periodTwo.inLine,
              meta: {
                level: UtilizationLevel.InLine,
                episodeDayRange: { min: 31, max: 60 },
              },
            },
          ],
        },
        {
          name: UtilizationLevelLabels[UtilizationLevel.Over],
          data: [
            {
              x: 'Period 1',
              y: result.periodOne.over,
              meta: {
                level: UtilizationLevel.Over,
                episodeDayRange: { min: 1, max: 30 },
              },
            },
            {
              x: 'Period 2',
              y: result.periodTwo.over,
              meta: {
                level: UtilizationLevel.Over,
                episodeDayRange: { min: 31, max: 60 },
              },
            },
          ],
        },
      ];
      this.updateColors();
    } finally {
      this.isLoading = false;
      // required to detect change and refresh the graph
      this.changeDetectorRefs.detectChanges();
    }
  };

  dataLabel = (
    val: UtilizationLevel,
    { seriesIndex }: { seriesIndex: number }
  ) => this.series[seriesIndex].name;

  formatToInt = (val: number, index: any) => {
    return val.toLocaleString();
  };

  handleSelect = (
    _: Event,
    chartContext: any,
    {
      dataPointIndex,
      seriesIndex,
      selectedDataPoints,
      w: {
        config: { series },
      },
    }: any
  ) => {
    this.wasClicked = true;
    if (!selectedDataPoints[seriesIndex]?.length) {
      this.levelSelected.emit(null);
    } else {
      const selection = series[seriesIndex].data[dataPointIndex]
        .meta as VisitUtilizationChartSelection;
      this.levelSelected.emit(selection);
    }
  };
}
