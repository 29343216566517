import { NgModule } from '@angular/core';
import { PopulationRoutingModule } from './population-routing.module';
import { HospitalizationRiskChartCardComponent } from './chart-cards/hospitalization-risk-chart-card/hospitalization-risk-chart-card.component';
import { AtlasCardModule } from '@wellsky/atlas-ui/card';
import { AtlasButtonModule } from '@wellsky/atlas-ui/button';
import { MatIconModule } from '@angular/material/icon';
import { LupaRiskChartCardComponent } from './chart-cards/lupa-risk-chart-card/lupa-risk-chart-card.component';
import { VisitUtilizationChartCardComponent } from './chart-cards/visit-utilization-chart-card/visit-utilization-chart-card.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { PopulationComponent } from './population.component';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { PopulationService } from './population.service';
import {
  AtlasChipsModule,
  AtlasSpinnerModule,
  AtlasTooltipModule,
} from '@wellsky/atlas-ui';
import { ComorbidityCellComponent } from './cells/comorbidity-cell/comorbidity-cell.component';
import { LupaRiskCellComponent } from './cells/lupa-risk-cell/lupa-risk-cell.component';
import { VisitUtilizationCellComponent } from './cells/visit-utilization-cell/visit-utilization-cell.component';
import { HospitalizationRiskCellComponent } from './cells/hospitalization-risk-cell/hospitalization-risk-cell.component';
import { HospiceSuitabilityCellComponent } from './cells/hospice-suitability-cell/hospice-suitability-cell.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AdlImprovementCellComponent } from './cells/adl-improvement-cell/adl-improvement-cell.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FilterDrawerComponent } from './filters/filter-drawer/filter-drawer.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SearchDropdownComponent } from './filters/search-dropdown/search-dropdown.component';
import { FilterChipsComponent } from './filters/filter-chips/filter-chips.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RangeInputComponent } from './filters/range-input/range-input.component';
import { CoreModule } from '../core/core.module';
import { AlertsCellComponent } from './cells/alerts-cell/alerts-cell.component';
import { NotificationWidgetModule } from '@wellsky/ai-notification/notification-widget';

@NgModule({
  providers: [PopulationService],
  declarations: [
    HospitalizationRiskChartCardComponent,
    LupaRiskChartCardComponent,
    VisitUtilizationChartCardComponent,
    PopulationComponent,
    ComorbidityCellComponent,
    LupaRiskCellComponent,
    VisitUtilizationCellComponent,
    HospitalizationRiskCellComponent,
    HospiceSuitabilityCellComponent,
    AdlImprovementCellComponent,
    FilterDrawerComponent,
    SearchDropdownComponent,
    FilterChipsComponent,
    RangeInputComponent,
    AlertsCellComponent,
  ],
  imports: [
    CommonModule,
    PopulationRoutingModule,
    AtlasCardModule,
    AtlasButtonModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    AtlasSpinnerModule,
    AtlasTooltipModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    AtlasChipsModule,
    MatMenuModule,
    CoreModule,
    NotificationWidgetModule,
  ],
  exports: [],
})
export class PopulationModule {}
