import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { Observable, firstValueFrom, from, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Only run on requests to the API
    if (!req.url.startsWith(environment.apiURL)) {
      return next.handle(req);
    }

    return from(this.handle(req, next));
  }

  private async handle(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const setHeaders: { [name: string]: string } = {};
    setHeaders.Authorization = await firstValueFrom(AuthService.authToken$);

    const newReq = req.clone({ setHeaders });
    return lastValueFrom(next.handle(newReq));
  }
}
