export enum FlagKey {
  ShowPopulationViewTiles = 'show_population_view_tiles',
  ShowPopulationViewFilters = 'population_view_filters',
  ShowPatientViewPatientInfoPdgmCard = 'patient_view_patient_info_pdgm_card',
  ShowPatientViewVitalsDiagnosisCard = 'patient_view_vitals_diagnosis_card',
  ShowPatientViewUtilizationInsightsCard = 'patient_view_utilization_insights_card',
  ShowPatientViewHospitalizationRiskCard = 'patient_view_hospitalization_risk_card',
  ShowPatientViewCarePlanCard = 'patient_view_care_plan_card',
  ShowPatientViewHospiceSuitabilityCard = 'patient_view_hospice_suitability_card',
  AllowPopulationViewPatientNavigation = 'population_view_to_patient_view_navigation',
  ShowPatientViewReferralSource = 'patient_view_referral_source',
  EnableAlerts = 'enable_alerts',
  ShowPopulationViewVirtualVisits = 'population_view_virtual_visits',
}

export interface FlagValue {
  flagKey: FlagKey;
  variantKey?: string;
}
