import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { PaginatedResponse } from '../models/Pagination';
import { finalize, map } from 'rxjs/operators';
import { HttpClient, HttpRequest } from '@angular/common/http';

type FetchMoreHandler<TItem> = (
  pageNumber: number
) => Observable<PaginatedResponse<TItem>>;

export class IncrementalLoadingBaseDatasource<TItem>
  implements DataSource<TItem> {
  protected itemsSubject = new BehaviorSubject<TItem[]>([]);
  protected isLoadingSubject = new BehaviorSubject<boolean>(false);
  protected totalCountSubject = new BehaviorSubject<number>(0);

  public isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();
  public totalCount$: Observable<number> = this.totalCountSubject.asObservable();
  public items$: Observable<TItem[]> = this.itemsSubject.asObservable();

  private currentPage = 1;
  private currentItems: TItem[] = [];
  private fetchInProgress = false;

  constructor(private fetchMoreHandler: FetchMoreHandler<TItem>) {}

  reset = () => {
    this.currentPage = 1;
    this.totalCountSubject.next(0);
    this.itemsSubject.next([]);
    this.currentItems = [];
    this.fetchMore();
  };

  fetchMore = () => {
    if (this.fetchInProgress) {
      return;
    }
    this.fetchInProgress = true;
    this.isLoadingSubject.next(true);
    this.fetchMoreHandler(this.currentPage)
      .pipe(
        finalize(() => {
          this.fetchInProgress = false;
        })
      )
      .subscribe((res) => {
        this.itemsSubject.next([...this.currentItems, ...res.result.data]);
        this.totalCountSubject.next(res.result.totalCount);
        this.isLoadingSubject.next(false);
        this.currentItems = this.itemsSubject.value;
        this.currentPage++;
      });
  };

  connect = (): Observable<TItem[]> => this.items$;

  disconnect = (): void => {
    this.itemsSubject.complete();
    this.isLoadingSubject.complete();
    this.totalCountSubject.complete();
  };
}
