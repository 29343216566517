<mat-form-field [appearance]="appearance ?? 'fill'">
  <mat-label>{{ label }}</mat-label>
  <atlas-chip-list>
    <atlas-chip
      *ngFor="let selection of selections"
      [removable]="
        selections.length > 1 || selection.value !== (userOu | async)?.id
      "
      [selectable]="false"
      (removed)="removeOrganizationalUnit(selection.value)"
    >
      {{ selection.label }}
      <mat-icon
        *ngIf="
          selections.length > 1 || selection.value !== (userOu | async)?.id
        "
        atlasChipRemove
      >
        cancel
      </mat-icon>
    </atlas-chip>
    <button
      atlas-icon-button
      (click)="addOrganizationalUnit()"
      [disabled]="disabled"
    >
      <mat-icon class="add-ou-icon">add</mat-icon>
    </button>
  </atlas-chip-list>
</mat-form-field>
