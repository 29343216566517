import { Component, Input, OnInit } from '@angular/core';
import { PatientReadModel } from '../../../models/PatientReadModel';
import { NotificationDto } from '@wellsky/ai-notification/core/src/models/NotificationDto';

@Component({
  selector: 'alerts-cell',
  templateUrl: './alerts-cell.component.html',
  styleUrls: ['./alerts-cell.component.scss'],
})
export class AlertsCellComponent {
  @Input() patient!: PatientReadModel;

  public handleNotificationSelection({ referenceKey }: NotificationDto) {
    window.open(`/patient/${referenceKey}/notifications`);
  }
}
