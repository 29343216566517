import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ATLAS_BANNER_DATA, AtlasBannerRef } from '@wellsky/atlas-ui';

export interface ApiErrorBannerData {
  message: string;
}

@Component({
  selector: 'app-banner-api-error',
  templateUrl: './api-error-banner.component.html',
  styleUrls: ['./api-error-banner.component.scss'],
})
export class ApiErrorBannerComponent implements OnInit, OnDestroy {
  private timer?: number;

  constructor(
    @Inject(ATLAS_BANNER_DATA) public data: ApiErrorBannerData,
    private bannerRef: AtlasBannerRef<ApiErrorBannerComponent>
  ) {}

  ngOnInit() {
    this.timer = setTimeout(() => this.dismiss(), 10 * 1000);
  }

  ngOnDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  dismiss() {
    // Trigger close for banner
    this.bannerRef.close();
  }
}
