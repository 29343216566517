import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter, tap } from 'rxjs/operators';
import {
  DataGridFiltersService,
  FilterSelection,
} from '@wellsky/ai-dataview-ui/filters';

export interface FilterChip {
  label: string;
  displayValue: any;
  removable: boolean;
  removeFilter(): void;
}

@UntilDestroy()
@Component({
  selector: 'filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
})
export class FilterChipsComponent implements OnInit {
  chips: FilterChip[] = [];

  constructor(private filtersService: DataGridFiltersService) {}

  ngOnInit(): void {
    this.filtersService.formGroup.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(300),
        filter(() => this.filtersService.formGroup.valid)
      )
      .subscribe(() => this.updateChips());
    this.updateChips();
  }

  public removeAllFilters() {
    this.filtersService.reset();
  }

  private async updateChips() {
    const selections = await this.filtersService.getSelections();
    this.chips = selections.filter(this.showChip).map((selection) => {
      return {
        label: selection.field.label,
        displayValue: selection.displayValue,
        removeFilter: selection.removeFromFilters,
        removable: !selection.isDefaultValue,
      };
    });
  }

  private showChip<T>(selection: FilterSelection<T>): boolean {
    switch (selection.field.slug) {
      case 'recursive':
        return false;
      case 'search':
        return selection.displayValue.length > 0;
      default:
        return true;
    }
  }
}
