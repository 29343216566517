import { Component, Input, OnInit } from '@angular/core';
import {
  HospiceSuitabilityStratification,
  HospiceSuitabilityStratificationLabels,
} from '../../../models/HospiceSuitability';

@Component({
  selector: 'hospice-suitability-cell',
  templateUrl: './hospice-suitability-cell.component.html',
  styleUrls: ['./hospice-suitability-cell.component.scss'],
})
export class HospiceSuitabilityCellComponent implements OnInit {
  @Input() hospiceSuitabilityStratification:
    | HospiceSuitabilityStratification
    | undefined;

  label = '';

  constructor() {}

  ngOnInit(): void {
    if (this.hospiceSuitabilityStratification) {
      this.label =
        this.hospiceSuitabilityStratification ===
        HospiceSuitabilityStratification.NotSuitable
          ? ''
          : HospiceSuitabilityStratificationLabels[
              this.hospiceSuitabilityStratification
            ];
    }
  }
}
