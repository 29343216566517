<app-notification-widget
  title="{{ patient.lastName | titlecase }}, {{
    patient.firstName | titlecase
  }}"
  [referenceKey]="patient.patientId.toString()"
  [highlightSeverity]="true"
  [hideWhenNoNotifications]="true"
  (notificationSelected)="handleNotificationSelection($event)"
>
</app-notification-widget>
