import { AuthService } from '../services/auth.service';
import { map, switchMap } from 'rxjs/operators';
import {
  BooleanFilterField,
  MultiSelectFilterField,
  RangeFilterField,
  TextFilterField,
} from '@wellsky/ai-dataview-ui/filters';
import { OrganizationalUnit } from '../models/OrganizationalUnit';
import { SelectOption } from '@wellsky/ai-dataview-ui/filters/src/models/SelectOption';
import { FilterOptionsService } from './filters/filter-options.service';
import {
  UtilizationLevel,
  UtilizationLevelLabels,
} from '../models/UtilizationLevel';
import { LupaVisitCompletionStatus } from '../models/LupaRisk';
import {
  HospitalizationRiskStratification,
  HospitalizationRiskStratificationLabels,
} from '../models/HospitalizationRisk';
import {
  HospiceSuitabilityStratification,
  HospiceSuitabilityStratificationLabels,
} from '../models/HospiceSuitability';
import {
  AdlCompositeStratification,
  AdlCompositeStratificationLabels,
} from '../models/AdlComposite';
import { ReferralSource, ReferralSourceLabels } from '../models/ReferralSource';
import {
  ComorbidityLevel,
  ComorbidityLevelLabels,
} from '../models/ComorbidityLevel';
import {
  PdgmClinicalGroup,
  PdgmClinicalGroupLabels,
} from '../models/PdgmClinicalGroup';
import {
  PdgmFunctionalImpairmentLevel,
  PdgmFunctionalImpairmentLevelLabels,
} from '../models/PdgmFunctionalImpairmentLevel';
import { GridColumn, SortDirection } from '@wellsky/ai-dataview-ui/grid';
import { FlagKey } from '../models/Flags';

const visitUtilizationLevelOptions: SelectOption[] = Object.values(
  UtilizationLevel
).map((level) => ({
  label: UtilizationLevelLabels[level],
  value: level,
}));

const lupaRiskOptions: SelectOption[] = [
  { label: 'At risk', value: LupaVisitCompletionStatus.AtRisk },
  { label: 'Not at risk', value: LupaVisitCompletionStatus.NotAtRisk },
];

const hospitalizationRiskOptions: SelectOption[] = Object.values(
  HospitalizationRiskStratification
).map((value) => ({
  label: HospitalizationRiskStratificationLabels[value],
  value: value,
}));

const hospiceSuitabilityOptions: SelectOption[] = Object.values(
  HospiceSuitabilityStratification
).map((value) => ({
  label: HospiceSuitabilityStratificationLabels[value],
  value: value,
}));

const adlCompositeStratificationOptions: SelectOption[] = [
  {
    label: AdlCompositeStratificationLabels[AdlCompositeStratification.Low],
    value: AdlCompositeStratification.Low,
  },
  {
    label: AdlCompositeStratificationLabels[AdlCompositeStratification.Medium],
    value: AdlCompositeStratification.Medium,
  },
  {
    label: AdlCompositeStratificationLabels[AdlCompositeStratification.High],
    value: AdlCompositeStratification.High,
  },
];

const referralSourceOptions: SelectOption[] = Object.values(ReferralSource).map(
  (value) => ({
    label: ReferralSourceLabels[value],
    value: value,
  })
);

const comorbidityLevelOptions: SelectOption[] = Object.values(
  ComorbidityLevel
).map((value) => ({
  label: ComorbidityLevelLabels[value],
  value: value,
}));

const pdgmClinicalGroupOptions: SelectOption[] = Object.values(
  PdgmClinicalGroup
).map((group) => ({
  label: PdgmClinicalGroupLabels[group],
  value: group,
}));

const pdgmFunctionalImpairmentLevelOptions: SelectOption[] = Object.values(
  PdgmFunctionalImpairmentLevel
).map((impairmentLevel) => ({
  label: PdgmFunctionalImpairmentLevelLabels[impairmentLevel],
  value: impairmentLevel,
}));

export const PopulationFilterFieldsFactory = (
  filterOptionsService: FilterOptionsService
) => [
  new TextFilterField('search', 'Search', ''),
  new MultiSelectFilterField(
    'organizationalUnitIDs',
    'Location',
    [],
    AuthService.currentUser$.pipe(
      switchMap((u) => u.metadata.organizationalUnit$),
      map((unit: OrganizationalUnit): SelectOption[] => [
        { value: unit.id, label: unit.name },
      ])
    )
  ),
  new MultiSelectFilterField(
    'referringFacilities',
    'Referral Source',
    filterOptionsService.referringFacilityOptions$
  ),
  new MultiSelectFilterField(
    'caseManagers',
    'Case Manager',
    filterOptionsService.caseManagerOptions$
  ),
  new MultiSelectFilterField(
    'visitUtilizationLevels',
    'Utilization Assessment',
    visitUtilizationLevelOptions
  ),
  new MultiSelectFilterField(
    'lupaVisitCompletionStatuses',
    'LUPA Risk',
    lupaRiskOptions
  ),
  new MultiSelectFilterField(
    'hospitalizationRiskStratifications',
    'Hospitalization Risk',
    hospitalizationRiskOptions
  ),
  new MultiSelectFilterField(
    'hospiceSuitabilityStratifications',
    'Hospice Suitability',
    hospiceSuitabilityOptions
  ),
  new MultiSelectFilterField(
    'adlCompositeStratifications',
    'ADL Improvement',
    adlCompositeStratificationOptions
  ),
  new RangeFilterField('episodeDay', 'Episode Day'),
  new MultiSelectFilterField(
    'referralSources',
    'Referral Type',
    referralSourceOptions
  ),
  new MultiSelectFilterField(
    'payerNames',
    'Insurance',
    filterOptionsService.payerNameOptions$
  ),
  new MultiSelectFilterField(
    'pdgmClinicalGroups',
    'PDGM Clinical Group',
    pdgmClinicalGroupOptions
  ),
  new MultiSelectFilterField(
    'pdgmFunctionalImpairmentLevels',
    'Functional Impairment Level',
    pdgmFunctionalImpairmentLevelOptions
  ),
  new MultiSelectFilterField(
    'comorbidityLevels',
    'Comorbidity Level',
    comorbidityLevelOptions
  ),
  new MultiSelectFilterField(
    'referringPhysicians',
    'Referring Physician',
    filterOptionsService.referringPhysicianOptions$
  ),
  new BooleanFilterField('recursive', 'Recursive', true, [], true),
];

export const PopulationGridColumns: GridColumn[] = [
  new GridColumn('alerts', { sort: [] }),
  new GridColumn('lastName', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
  new GridColumn('firstName', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
  new GridColumn('hospitalizationRiskScaled', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
  new GridColumn('primaryDiagnosisCode', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
  new GridColumn('comorbidityLevel', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
  new GridColumn('hospiceSuitability', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
  new GridColumn('adlComposite', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
  new GridColumn('totalVisitCount', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
  new GridColumn('totalInPersonVisitCount', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
  new GridColumn('visitUtilizationLevel', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
  new GridColumn('totalVirtualVisitCount', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
  new GridColumn('lupaRisk', {
    sort: [SortDirection.Ascending, SortDirection.Descending],
  }),
];
