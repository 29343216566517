<span
  class="hospice-suitability-cell"
  [class.suitable]="
    hospiceSuitabilityStratification === 'marginallySuitable' ||
    hospiceSuitabilityStratification === 'potentiallySuitable'
  "
  [class.not-likely-suitable]="
    hospiceSuitabilityStratification === 'notLikelySuitable'
  "
>
  <img
    *ngIf="hospiceSuitabilityStratification === 'marginallySuitable'"
    src="assets/hospice-suitability-marginal.svg"
    width="16px"
    alt="Marginally suitable for hospice"
  />
  <img
    *ngIf="hospiceSuitabilityStratification === 'potentiallySuitable'"
    src="assets/hospice-suitability-potential.svg"
    width="16px"
    alt="Potentially suitable for hospice"
  />
  {{ label }}
</span>
