import * as dayjs from 'dayjs';
import { isNonNullable } from './general.utils';
import { Dayjs } from 'dayjs';

const TEN_DAYS = 10 * 24 * 60 * 60 * 1000;

export interface LupaDetails {
  thresholdMet: boolean;
  atRisk: boolean;
  displayFraction: string;
  iconName?: string;
}

export const getLupaRiskDetails = (
  completedVisitCount: number | undefined,
  lupaMinimumVisitCount: number | undefined,
  billingPeriodEndDate: Dayjs
): LupaDetails | undefined => {
  if (
    isNonNullable(completedVisitCount) &&
    isNonNullable(lupaMinimumVisitCount) &&
    billingPeriodEndDate
  ) {
    const remainingTimeInBillingPeriod = billingPeriodEndDate.diff(dayjs());

    // No improper fractions :)
    const displayFraction = `${Math.min(
      completedVisitCount,
      lupaMinimumVisitCount
    )}/${lupaMinimumVisitCount}`;

    const thresholdMet = completedVisitCount >= lupaMinimumVisitCount;

    const atRisk =
      !thresholdMet &&
      remainingTimeInBillingPeriod > 0 &&
      remainingTimeInBillingPeriod <= TEN_DAYS;

    const iconName = (() => {
      if (thresholdMet) {
        return 'task_alt';
      }
      if (atRisk) {
        return 'access_time';
      }
      return undefined;
    })();

    return {
      displayFraction,
      thresholdMet,
      atRisk,
      iconName,
    };
  } else {
    return undefined;
  }
};
