import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserRole } from './models/AuthorizedUser';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { RoleGuard } from './guards/RoleGuard';
import { FeatureFlagGuard } from './guards/FeatureFlagGuard';
import { FlagKey } from './models/Flags';

const routes: Routes = [
  { path: '', redirectTo: '/population', pathMatch: 'full' },
  {
    canActivate: [OktaAuthGuard, RoleGuard],
    data: { allowedRoles: [UserRole.User] },
    path: 'population',
    title: 'CareInsights for Home Health',
    loadChildren: () =>
      import('./population/population.module').then((m) => m.PopulationModule),
  },
  {
    canActivate: [OktaAuthGuard, RoleGuard],
    data: { allowedRoles: [UserRole.User] },
    path: 'patient/:patientId',
    title: 'CareInsights for Home Health',
    loadChildren: () =>
      import('./patient/patient.module').then((m) => m.PatientModule),
  },
  {
    path: 'admin',
    canActivate: [OktaAuthGuard, RoleGuard],
    data: { allowedRoles: [UserRole.Admin] },
    title: 'CareInsights for Home Health',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'login/callback',
    title: 'CareInsights for Home Health',
    component: OktaCallbackComponent,
  },
  {
    path: 'alerts',
    canActivate: [OktaAuthGuard, RoleGuard, FeatureFlagGuard],
    data: { allowedRoles: [UserRole.User], flagKey: FlagKey.EnableAlerts },
    loadChildren: () =>
      import('./alerts/alerts.module').then((m) => m.AlertsModule),
  },
  // Catch-all, in case of invalid path.
  { path: '**', redirectTo: '/population' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
