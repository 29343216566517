// The order of this enum is intentional. Ordered by the suitability level
export enum HospiceSuitabilityStratification {
  PotentiallySuitable = 'potentiallySuitable',
  MarginallySuitable = 'marginallySuitable',
  NotLikelySuitable = 'notLikelySuitable',
  NotSuitable = 'notSuitable',
}

export const HospiceSuitabilityStratificationLabels: Record<
  HospiceSuitabilityStratification,
  string
> = {
  [HospiceSuitabilityStratification.PotentiallySuitable]:
    'Potentially suitable',
  [HospiceSuitabilityStratification.MarginallySuitable]: 'Marginally suitable',
  [HospiceSuitabilityStratification.NotLikelySuitable]: 'Not likely suitable',
  [HospiceSuitabilityStratification.NotSuitable]: 'Not suitable',
};

export const HospiceSuitabilityStratificationColors: Record<
  HospiceSuitabilityStratification,
  string
> = {
  [HospiceSuitabilityStratification.PotentiallySuitable]: '#9B51E0',
  [HospiceSuitabilityStratification.MarginallySuitable]: '#BB6BD9',
  [HospiceSuitabilityStratification.NotLikelySuitable]: '#828282',
  [HospiceSuitabilityStratification.NotSuitable]: '#BDBDBD',
};
