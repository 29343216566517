<div
  class="lupa-risk-cell"
  [class.at-risk]="this.lupaDetails?.atRisk"
  [class.threshold-met]="this.lupaDetails?.thresholdMet"
  [class.not-enough-data]="!this.lupaDetails"
>
  <mat-icon *ngIf="this.lupaDetails?.atRisk"> access_time </mat-icon>
  <mat-icon *ngIf="this.lupaDetails?.thresholdMet"> task_alt </mat-icon>
  <span
    class="label"
    >{{ this.lupaDetails?.displayFraction ?? 'Not enough data' }}</span
  >
</div>
