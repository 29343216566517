import { Component, OnInit } from '@angular/core';
import { createSlider, Slider } from '@typeform/embed';

const TYPEFORM_FORM_ID = 'fsW6v9';

@Component({
  selector: 'typeform-survey',
  templateUrl: './typeform-survey.component.html',
  styleUrls: ['./typeform-survey.component.scss'],
})
export class TypeformSurveyComponent implements OnInit {
  hidden: boolean = false;

  private typeformSlider?: Slider;

  constructor() {}

  ngOnInit(): void {
    this.typeformSlider = createSlider(TYPEFORM_FORM_ID, {
      width: 550,
      position: 'right',
    });
  }

  hide() {
    this.hidden = true;
  }

  toggle() {
    this.typeformSlider!.toggle();
  }
}
