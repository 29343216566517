import { titleCase } from '../utils/string.utils';

export interface CaseManager {
  firstName: string;
  lastName: string;
}

export const getCaseManagerDisplayName = (cm: CaseManager): string => {
  let str = cm.lastName;

  if (cm.firstName) {
    if (cm.lastName) {
      str += ', ';
    }
    str += cm.firstName;
  }

  return titleCase(str ?? '');
};
