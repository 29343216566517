<atlas-topnav class="navbar">
  <div atlas-topnav-logo>
    <div class="d-flex align-item-center">
      <div class="mt-1">
        <img src="assets/wellsky-logo.svg" width="160px" alt="WellSky Logo" />
      </div>
      <div class="app-title me-auto">CareInsights for Home Health</div>

      <div atlas-topnav-action>
        <atlas-tab-nav-bar>
          <atlas-tab-link
            routerLink="/population"
            [active]="router.isActive('/population', false)"
            *ngIf="hasUserRole"
          >
            Population
          </atlas-tab-link>
        </atlas-tab-nav-bar>
      </div>

      <div class="mt-1" *ngIf="userIsLoggedIn">
        <ng-container *ngIf="showAlertsWidget$ | async">
          <app-notification-widget
            data-test-id="navbar_notification_widget"
            alertManagerUrl="/alerts"
            [showAlertManagerLink]="true"
            [enableCache]="true"
            [enablePolling]="true"
            [showBadge]="true"
            (notificationSelected)="handleNotificationSelection($event)"
          >
          </app-notification-widget>
        </ng-container>
        <atlas-menu-trigger [atlasMenuTrigger]="atlasMenu">
          <button atlas-icon-button aria-label="Account Circle Icon Button">
            <mat-icon>account_circle</mat-icon>
          </button>
        </atlas-menu-trigger>

        <atlas-menu #atlasMenu="atlasMenu">
          <ng-container *ngIf="userIsLoggedIn">
            <ng-container *ngIf="hasAdminRole">
              <ng-template atlas-menu-item>
                <a [routerLink]="['/admin/users']">Manage users</a>
              </ng-template>
              <ng-template atlas-menu-item [divider]="true">
                <a [routerLink]="['/admin/organization']"
                  >Manage organization</a
                >
              </ng-template>
            </ng-container>
            <ng-template atlas-menu-item>
              <div (click)="logout()">Sign out</div>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="!userIsLoggedIn">
            <ng-template atlas-menu-item>
              <div (click)="login()">Login</div>
            </ng-template>
          </ng-container>
        </atlas-menu>
      </div>
    </div>
  </div>
</atlas-topnav>
