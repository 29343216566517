<atlas-card>
  <atlas-card-header>
    <atlas-card-title>Hospitalization Risk</atlas-card-title>
  </atlas-card-header>
  <atlas-card-content>
    <div
      *ngIf="isLoading"
      class="loading-spinner"
      data-test-id="loading-indicator"
    >
      <atlas-spinner></atlas-spinner>
    </div>
    <div *ngIf="!isLoading" class="chart-container">
      <apx-chart
        [series]="series"
        [tooltip]="{
          y: {
            title: {
              formatter: $any(getTooltipStratificationLabel)
            }
          }
        }"
        [grid]="{
          padding: {
            left: 0,
            right: 0
          }
        }"
        [xaxis]="{
          labels: {
            show: false
          }
        }"
        [chart]="{
          height: 172,
          width: '100%',
          type: 'treemap',
          toolbar: { show: false },
          animations: { enabled: false },
          events: {
            dataPointSelection: handleSelect
          }
        }"
        [stroke]="{ width: 2 }"
        [legend]="{
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          offsetX: -33,
          offsetY: 18,
          showForSingleSeries: true,
          customLegendItems: legendLabels,
          fontFamily: 'Roboto',
          fontWeight: 300,
          markers: {
            fillColors: legendColors,
            radius: 0,
            width: 8,
            height: 8
          },
          labels: {
            colors: ['#43505E']
          }
        }"
        [title]="{ margin: 0 }"
        [dataLabels]="{
          formatter: formatLabel,
          offsetY: 7,
          offsetX: 7,
          style: {
            fontSize: '13',
            fontFamily: 'Roboto, Sans-Serif'
          }
        }"
        [plotOptions]="
          $any({
            treemap: {
              distributed: true,
              enableShades: false,
              dataLabels: {
                textAnchorHorizontal: 'start',
                textAnchorVertical: 'start',
                autoAdjustFontSize: false
              }
            }
          })
        "
        [states]="{
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }
        }"
      ></apx-chart>
    </div>
  </atlas-card-content>
</atlas-card>
