import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {
  AtlasChipsModule,
  AtlasDialogModule,
  AtlasInputModule,
} from '@wellsky/atlas-ui';
import { AtlasButtonModule } from '@wellsky/atlas-ui/button';
import { LastPipe } from './pipes/last.pipe';
import { EnableTooltipOnOverflowDirective } from './directives/enable-tooltip-onoverflow.directive';
import { OrganizationalUnitSelectorComponent } from './organizational-unit-selector/organizational-unit-selector.component';
import { OrganizationalUnitSearchComponent } from './forms/controls/organizational-unit-search/organizational-unit-search.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    ConfirmationDialogComponent,
    LastPipe,
    EnableTooltipOnOverflowDirective,
    OrganizationalUnitSelectorComponent,
    OrganizationalUnitSearchComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AtlasDialogModule,
    AtlasButtonModule,
    AtlasDialogModule,
    MatLegacyFormFieldModule,
    AtlasChipsModule,
    MatIconModule,
    MatLegacyListModule,
    ScrollingModule,
    AtlasInputModule,
    ReactiveFormsModule,
    MatProgressBarModule,
  ],
  exports: [
    BreadcrumbComponent,
    LastPipe,
    EnableTooltipOnOverflowDirective,
    OrganizationalUnitSearchComponent,
  ],
})
export class CoreModule {}
