<div class="filter-chips">
  <!-- Horizontal -->
  <atlas-chip-list
    class="horizontal-chip-list"
    [selectable]="false"
    data-test-id="horizontal-filter-chips"
  >
    <ng-container *ngFor="let chip of chips">
      <atlas-chip [removable]="chip.removable" (removed)="chip.removeFilter()">
        {{ chip.label }}: {{ chip.displayValue }}
        <mat-icon *ngIf="chip.removable" atlasChipRemove>cancel</mat-icon>
      </atlas-chip>
    </ng-container>
  </atlas-chip-list>

  <!-- Ellipsis dropdown -->
  <button
    atlas-icon-button
    *ngIf="chips.length > 0"
    [matMenuTriggerFor]="chipsDropdown"
    class="dropdown-trigger-button"
    data-test-id="filter-chip-dropdown-button"
  >
    <mat-icon>more_horiz</mat-icon>
  </button>

  <mat-menu #chipsDropdown="matMenu">
    <ng-template matMenuContent>
      <div class="dropdown-title">CURRENT SELECTIONS</div>
      <div class="dropdown-content">
        <div class="dropdown-chip-list">
          <atlas-chip-list
            class="mat-chip-list-stacked"
            [selectable]="false"
            aria-orientation="vertical"
            data-test-id="dropdown-filter-chips"
          >
            <ng-container *ngFor="let chip of chips">
              <atlas-chip
                class="dropdown-chip"
                [removable]="chip.removable"
                (removed)="chip.removeFilter()"
              >
                {{ chip.label }}: {{ chip.displayValue }}
                <mat-icon atlasChipRemove>cancel</mat-icon>
              </atlas-chip>
            </ng-container>
          </atlas-chip-list>
        </div>
        <button
          atlas-button
          color="warn"
          class="dropdown-clear-button"
          (click)="removeAllFilters()"
        >
          Clear All Filters
        </button>
      </div>
    </ng-template>
  </mat-menu>
</div>
