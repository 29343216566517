export const enum AdlCompositeStratification {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export const AdlCompositeStratificationLabels: Record<
  AdlCompositeStratification,
  string
> = {
  [AdlCompositeStratification.Low]: 'Less Likely',
  [AdlCompositeStratification.Medium]: 'Likely',
  [AdlCompositeStratification.High]: 'Highly Likely',
};
