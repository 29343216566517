import { OrganizationalUnit } from './OrganizationalUnit';
import { Observable } from 'rxjs';
import { UserClaims } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';

export class AuthorizedUser {
  static fromTokenClaims(
    idTokenClaims: UserClaims,
    accessTokenClaims: UserClaims | undefined
  ): AuthorizedUser {
    const { name, email, sub } = idTokenClaims;
    const roles = this.getUserRoles(accessTokenClaims);

    return new AuthorizedUser(sub, email!, name, roles);
  }

  public roles: Set<UserRole>;

  // @ts-ignore
  public metadata: UserMetadata;

  constructor(
    public id: string,
    public email: string,
    public name?: string,
    roles?: UserRole[]
  ) {
    this.roles = new Set(roles);
  }

  canAccessOrganizationalUnit = (ou: OrganizationalUnit): boolean =>
    this.canAccessOrganizationalUnitAtPath(ou.path);

  canAccessOrganizationalUnitAtPath = (path: string): boolean => {
    if (!this.metadata) {
      throw new Error('no user metadata');
    }
    const r = new RegExp(`\.?${this.metadata.organizationalUnitId}(\.|$)`);
    return r.test(path);
  };

  hasRole(role: UserRole): boolean {
    return this.roles.has(role);
  }

  private static getUserRoles(accessTokenClaims: any) {
    const ciRoles = accessTokenClaims[environment.ciRoleClaimName];
    const vbiRoles = accessTokenClaims[environment.vbiRoleClaimName];

    const roles: UserRole[] = [];

    // User
    if (ciRoles?.includes(UserRole.User)) {
      roles.push(UserRole.User);
    }

    // Admin (VBI admins are considered admins in our app too, so that they can manage users and OUs)
    if (
      ciRoles?.includes(UserRole.Admin) ||
      vbiRoles?.includes(UserRole.Admin)
    ) {
      roles.push(UserRole.Admin);
    }

    if (ciRoles?.includes(UserRole.Hosting)) {
      roles.push(UserRole.Hosting);
    }

    return roles;
  }
}

export enum Application {
  CareInsights = 'CareInsights',
  ValueBasedInsights = 'ValueBasedInsights',
}

export const ApplicationLabels: Record<Application, string> = {
  [Application.CareInsights]: 'CareInsights',
  [Application.ValueBasedInsights]: 'Value-Based Insights',
};

export enum UserRole {
  User = 'user',
  Admin = 'admin',
  Hosting = 'hosting',
}

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export interface UserMetadata {
  organizationalUnitId: number;
  organizationalUnit$: Observable<OrganizationalUnit>;
}

export const userRoleFromString = (str: string): UserRole => {
  const role = UserRole[str as keyof typeof UserRole];
  if (typeof role === 'undefined') {
    throw new Error(`unknown role ${str}`);
  }
  return role;
};
