import { AtlasResponseWrapper } from './AtlasResponseWrapper';

export enum ApiSortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface PaginatedResponse<TResponse>
  extends AtlasResponseWrapper<{
    totalCount: number;
    data: TResponse[];
  }> {}

export interface PaginatedRequest {
  page: number;
  pageSize: number;
  sortBy?: string;
  sortDirection?: ApiSortDirection;
}

export const emptyPaginatedResponse = <T>(): PaginatedResponse<T> => ({
  result: {
    totalCount: 0,
    data: [],
  },
  statusCode: 200,
});
