export enum ComorbidityLevel {
  High = 'high',
  Low = 'low',
  None = 'none',
}

export const ComorbidityLevelLabels: Record<ComorbidityLevel, string> = {
  [ComorbidityLevel.High]: 'High',
  [ComorbidityLevel.Low]: 'Low',
  [ComorbidityLevel.None]: 'None',
};
