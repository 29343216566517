import { titleCase } from './string.utils';

export const isNonNullable = <T>(v: T): v is NonNullable<T> => {
  return v !== undefined && v !== null;
};

export const getFirstNameLastName = (
  firstName?: string,
  lastName?: string
): string => {
  let str = firstName;

  if (firstName) {
    if (lastName) {
      str += ' ';
    }
    str += lastName ?? '';
  }

  return titleCase(str ?? '');
};
