import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { PaginatedResponse } from '../models/Pagination';
import { map } from 'rxjs/operators';

export abstract class PaginatedResultBaseDatasource<TResult>
  implements DataSource<TResult> {
  protected paginatedResponseSubject = new ReplaySubject<
    PaginatedResponse<TResult>
  >(1);
  protected isLoadingSubject = new BehaviorSubject<boolean>(false);

  public isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();
  public totalCount$: Observable<number> = this.paginatedResponseSubject.pipe(
    map((r) => r.result.totalCount)
  );

  connect = (): Observable<TResult[]> =>
    this.paginatedResponseSubject.pipe(map((r) => r.result.data));

  disconnect = (): void => {
    this.paginatedResponseSubject.complete();
    this.isLoadingSubject.complete();
  };
}
