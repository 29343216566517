import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {
  OrganizationalUnit,
  OrganizationalUnitDetailedPathPart,
  OrganizationalUnitType,
} from '../../models/OrganizationalUnit';
import { PaginatedRequest, PaginatedResponse } from '../../models/Pagination';
import { AtlasResponseWrapper } from '../../models/AtlasResponseWrapper';
import { getHttpParamsFromObject } from '../../utils/http.utils';
import { DISABLE_ERROR_BANNER_HEADER } from '../../interceptors/error.interceptor';
import { map } from 'rxjs/operators';
import { Application } from '../../models/AuthorizedUser';

@Injectable({
  providedIn: 'root',
})
export class OrganizationalUnitService {
  private static url = `${environment.apiURL}/organizational-unit`;

  constructor(private http: HttpClient) {}

  public getById = (
    id: number
  ): Observable<AtlasResponseWrapper<OrganizationalUnit>> =>
    this.http.get<AtlasResponseWrapper<OrganizationalUnit>>(
      `${OrganizationalUnitService.url}/${id}`
    );

  public getPathDetailsById = (
    id: number
  ): Observable<AtlasResponseWrapper<OrganizationalUnitDetailedPathPart[]>> =>
    this.http.get<AtlasResponseWrapper<OrganizationalUnitDetailedPathPart[]>>(
      `${OrganizationalUnitService.url}/${id}/path-details`
    );

  public getEnabledApps = (id: number): Observable<Application[]> =>
    this.http
      .get<AtlasResponseWrapper<Application[]>>(
        `${OrganizationalUnitService.url}/${id}/enabled-apps`
      )
      .pipe(map((res) => res.result));

  public getList = (
    req: OrganizationalUnitListRequest
  ): Observable<PaginatedResponse<OrganizationalUnit>> => {
    const params = getHttpParamsFromObject(req);
    return this.http.get<PaginatedResponse<OrganizationalUnit>>(
      OrganizationalUnitService.url,
      {
        params,
      }
    );
  };

  create = (
    organization: OrganizationCreateRequest,
    showErrorBanner = true
  ): Observable<AtlasResponseWrapper<OrganizationalUnit>> => {
    return this.http.post<AtlasResponseWrapper<OrganizationalUnit>>(
      OrganizationalUnitService.url,
      organization,
      {
        headers: {
          [DISABLE_ERROR_BANNER_HEADER]: showErrorBanner ? '' : 'true',
        },
      }
    );
  };

  update = (
    organization: OrganizationUpdateRequest,
    showErrorBanner = true
  ): Observable<AtlasResponseWrapper<OrganizationalUnit>> => {
    return this.http.put<AtlasResponseWrapper<OrganizationalUnit>>(
      `${OrganizationalUnitService.url}/${organization.id}`,
      organization,
      {
        headers: {
          [DISABLE_ERROR_BANNER_HEADER]: showErrorBanner ? '' : 'true',
        },
      }
    );
  };

  move = (
    id: number,
    req: OrganizationalUnitMoveRequest
  ): Observable<AtlasResponseWrapper<OrganizationalUnit>> =>
    this.http.post<AtlasResponseWrapper<OrganizationalUnit>>(
      `${OrganizationalUnitService.url}/${id}/move`,
      req
    );

  delete = (id: number): Observable<AtlasResponseWrapper<void>> =>
    this.http.delete<AtlasResponseWrapper<void>>(
      `${OrganizationalUnitService.url}/${id}`
    );
}

export interface OrganizationCreateRequest {
  parentId: number;
  name: string;
  description: string;
}

export interface OrganizationUpdateRequest {
  id: number;
  name: string;
  description: string;
}

export interface OrganizationalUnitMoveRequest {
  parentId: number;
}

export interface OrganizationalUnitListRequest extends PaginatedRequest {
  parentId: string | number;
  types?: OrganizationalUnitType[];
  search?: string;
  recursive?: boolean;
  allowAllOrganizations?: boolean;
}
