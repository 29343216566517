import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { appendArray } from '../utils/http.utils';
import { AtlasResponseWrapper } from '../models/AtlasResponseWrapper';
import { FlagKey, FlagValue } from '../models/Flags';
import { map } from 'rxjs/operators';

const FLAGS_URL = `${environment.apiURL}/flags`;

/**
 * To add a new flag, and have it automatically retrieved by this service,
 * simply add its Flagr key to the FlagKeys enum in models/Flags.ts
 */

@Injectable({
  providedIn: 'root',
})
export class FlagsService {
  private flagValueSubjects = Object.values(FlagKey).reduce(
    (acc, key) => ({
      ...acc,
      [key]: new ReplaySubject(1),
    }),
    {}
  ) as Record<FlagKey, ReplaySubject<string | null>>;

  constructor(private http: HttpClient) {
    this.loadFlags();
  }

  public flagValue(flagKey: FlagKey): Observable<string | null> {
    return this.flagValueSubjects[flagKey].asObservable();
  }

  public booleanFlagValue(flagKey: FlagKey): Observable<boolean> {
    return this.flagValue(flagKey).pipe(map((v) => v === 'on'));
  }

  private loadFlags() {
    let params = new HttpParams();
    params = appendArray(params, 'key', Object.values(FlagKey));
    this.http
      .get<AtlasResponseWrapper<FlagValue[]>>(FLAGS_URL, { params })
      .subscribe(({ result }) => {
        for (const { flagKey, variantKey } of Object.values(result)) {
          this.flagValueSubjects[flagKey].next(variantKey ?? null);
        }
      });
  }
}
