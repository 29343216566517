import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { PaginatedRequest, PaginatedResponse } from '../models/Pagination';
import { getHttpParamsFromObject } from '../utils/http.utils';
import { PatientReadModel } from '../models/PatientReadModel';
import {
  HospitalizationRiskStratification,
  HospitalizationRiskChartData,
} from '../models/HospitalizationRisk';
import { AtlasResponseWrapper } from '../models/AtlasResponseWrapper';
import { CaseManager } from '../models/CaseManager';
import { RangeValue } from '../models/Range';
import {
  UtilizationLevel,
  VisitUtilizationChartData,
} from '../models/UtilizationLevel';
import {
  LupaRiskChartData,
  LupaVisitCompletionStatus,
} from '../models/LupaRisk';
import { AdlCompositeStratification } from '../models/AdlComposite';
import { HospiceSuitabilityStratification } from '../models/HospiceSuitability';
import { ReferralSource } from '../models/ReferralSource';
import { ComorbidityLevel } from '../models/ComorbidityLevel';
import { ReferringPhysician } from '../models/ReferringPhysician';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Injectable()
export class PopulationService {
  private static url = `${environment.apiURL}/population`;

  constructor(private http: HttpClient) {}

  public getList = (
    filters: PopulationFilters,
    pagination: PaginatedRequest
  ): Observable<PaginatedResponse<PatientReadModel>> => {
    const params = getHttpParamsFromObject(pagination);
    return this.http.post<PaginatedResponse<PatientReadModel>>(
      `${PopulationService.url}/search`,
      filters,
      { params }
    );
  };

  public getHospitalizationRiskChartData = (
    req: HospitalizationRiskChartRequest
  ): Observable<AtlasResponseWrapper<HospitalizationRiskChartData>> => {
    return this.http.post<AtlasResponseWrapper<HospitalizationRiskChartData>>(
      `${PopulationService.url}/chart/hospitalization-risk`,
      req
    );
  };

  public getLupaRiskChartData = (
    req: LupaRiskChartRequest
  ): Observable<AtlasResponseWrapper<LupaRiskChartData>> => {
    return this.http.post<AtlasResponseWrapper<LupaRiskChartData>>(
      `${PopulationService.url}/chart/lupa-risk`,
      req
    );
  };

  public getVisitUtilizationChartData = (
    req: VisitUtilizationChartRequest
  ): Observable<AtlasResponseWrapper<VisitUtilizationChartData>> => {
    return this.http.post<AtlasResponseWrapper<VisitUtilizationChartData>>(
      `${PopulationService.url}/chart/visit-utilization`,
      req
    );
  };

  public getPopulationViewCsv = (filters: PopulationFilters) => {
    return this.http
      .post(`${PopulationService.url}/population-view/csv`, filters, {
        responseType: 'text',
      })
      .pipe(
        tap((res) => {
          const blob = new Blob([res.toString()], { type: 'text/csv' });
          saveAs(blob, 'PopulationViewExport.csv');
        })
      );
  };
}

export interface PopulationFilters {
  organizationalUnitIDs: number[];
  recursive?: boolean;
  hospitalizationRiskStratifications?: HospitalizationRiskStratification[];
  search?: string;
  referringFacilities?: string[];
  caseManagers?: CaseManager[];
  payerNames?: string[];
  pdgmClinicalGroups?: string[];
  episodeDay?: RangeValue;
  pdgmFunctionalImpairmentLevels?: string[];
  visitUtilizationLevels?: UtilizationLevel[];
  lupaVisitCompletionStatuses?: LupaVisitCompletionStatus[];
  hospiceSuitabilityStratifications?: HospiceSuitabilityStratification[];
  adlCompositeStratifications?: AdlCompositeStratification[];
  referralSources?: ReferralSource[];
  comorbidityLevels?: ComorbidityLevel[];
  referringPhysicians?: ReferringPhysician[];
}

export type PopulationListRequest = PopulationFilters & PaginatedRequest;

export type HospitalizationRiskChartRequest = Omit<
  PopulationFilters,
  'hospitalizationRiskStratifications'
>;

export type VisitUtilizationChartRequest = Omit<
  PopulationFilters,
  'episodeDay' | 'visitUtilizationLevels'
>;

export type LupaRiskChartRequest = Omit<
  PopulationFilters,
  'episodeDay' | 'lupaVisitCompletionStatuses'
>;
