// The order of this enum is intentional. Ordered by the level order, then "None"
export enum UtilizationLevel {
  Under = 'under',
  InLine = 'inLine',
  Over = 'over',
  None = 'none',
}

export const UtilizationLevelLabels: Record<UtilizationLevel, string> = {
  [UtilizationLevel.Under]: 'Under',
  [UtilizationLevel.InLine]: 'In Line',
  [UtilizationLevel.Over]: 'Over',
  [UtilizationLevel.None]: 'None',
};

export interface VisitUtilizationChartData {
  periodOne: {
    under: number;
    inLine: number;
    over: number;
  };
  periodTwo: {
    under: number;
    inLine: number;
    over: number;
  };
}
