import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { FlagsService } from '../services/flags.service';
import { FlagKey } from '../models/Flags';

@Injectable({ providedIn: 'root' })
export class FeatureFlagGuardService {
  constructor(private flagService: FlagsService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const flag = route.data.flagKey as FlagKey;
    if (!flag) {
      console.error(
        'flagKey must be provided to FeatureFlagGuard in route data'
      );
    }
    return this.flagService.booleanFlagValue(flag);
  }
}

export const FeatureFlagGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot
): Observable<boolean> => {
  return inject(FeatureFlagGuardService).canActivate(next);
};
