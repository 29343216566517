import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { SelectOption } from '@wellsky/ai-dataview-ui/filters';

@UntilDestroy()
@Component({
  selector: 'search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class SearchDropdownComponent<T> implements OnInit {
  @Input() items!: Observable<SelectOption[]> | SelectOption[];
  @Input() fc!: FormControl;
  @Input() placeholderLabel!: string;

  public filterControl: FormControl = new FormControl();
  public filteredItems: BehaviorSubject<SelectOption[]> = new BehaviorSubject<
    SelectOption[]
  >([]);

  @ViewChild('itemSelect', { static: true }) itemSelect!: MatSelect;

  constructor() {}

  ngOnInit() {
    if (this.items instanceof Observable) {
      this.items.subscribe((result) => {
        this.filteredItems.next(result);
      });
    } else {
      this.filteredItems.next(this.items);
    }

    this.filterControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateFilter();
    });
  }

  private updateFilter = () => {
    if (this.items instanceof Observable) {
      this.items.pipe(first()).subscribe(this.filterItems);
    } else {
      this.filterItems(this.items);
    }
  };

  private filterItems = (items: SelectOption[]) => {
    if (!items) {
      return;
    }

    let search = this.filterControl.value;
    if (!search) {
      this.filteredItems.next(items);
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredItems.next(
      items.filter(
        (item) => item.label.toString().toLowerCase().indexOf(search) > -1
      )
    );
  };
}
