// The order of this enum is intentional. Ordered by the level order, then "None"
export enum PdgmFunctionalImpairmentLevel {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  None = 'none',
}

export const PdgmFunctionalImpairmentLevelLabels: Record<
  PdgmFunctionalImpairmentLevel,
  string
> = {
  [PdgmFunctionalImpairmentLevel.Low]: 'Low',
  [PdgmFunctionalImpairmentLevel.Medium]: 'Medium',
  [PdgmFunctionalImpairmentLevel.High]: 'High',
  [PdgmFunctionalImpairmentLevel.None]: 'None',
};
