import { Component, Input, OnInit } from '@angular/core';
import { HospitalizationRiskStratification } from '../../../models/HospitalizationRisk';

@Component({
  selector: 'hospitalization-risk-cell',
  templateUrl: './hospitalization-risk-cell.component.html',
  styleUrls: ['./hospitalization-risk-cell.component.scss'],
})
export class HospitalizationRiskCellComponent implements OnInit {
  @Input() hospitalizationRisk: number | undefined;
  @Input() hospitalizationRiskStratification:
    | HospitalizationRiskStratification
    | undefined;

  constructor() {}

  ngOnInit(): void {}
}
