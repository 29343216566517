import { ComorbidityLevel } from './ComorbidityLevel';
import { UtilizationLevel } from './UtilizationLevel';
import { HospitalizationRiskStratification } from './HospitalizationRisk';
import { HospiceSuitabilityStratification } from './HospiceSuitability';
import { AdlCompositeStratification } from './AdlComposite';
import { Gender } from './Gender';
import { PdgmClinicalGroup } from './PdgmClinicalGroup';
import { PdgmFunctionalImpairmentLevel } from './PdgmFunctionalImpairmentLevel';
import { ReferralSource } from './ReferralSource';
import { EpisodeTiming } from './Episodetiming';
import { SocialDeterminantRiskLevel } from './SocialDeterminantRiskLevel';
import { Visit, VisitDiscipline } from './Visit';
import { UtilizationDistribution } from './UtilizationDistribution';
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

export interface PatientReadModel {
  id: string;
  patientId: number;
  firstName: string;
  lastName: string;
  gender: Gender;
  dateOfBirth: string;
  primaryDiagnosisCode: string;
  primaryDiagnosisDescription?: string;
  secondaryDiagnosisDescriptions?: string[];
  comorbidityLevel?: ComorbidityLevel;
  comorbidityGroups?: string;
  visitUtilizationLevel?: UtilizationLevel;
  lupaMinimumVisitCount?: number;
  completedVisitCount?: number;
  totalVisitCount?: number;
  totalInPersonVisitCount?: number;
  totalVirtualVisitCount?: number;
  hospitalizationRisk?: number;
  hospitalizationRiskScaled?: number;
  hospitalizationRiskStratification?: HospitalizationRiskStratification;
  hospiceSuitabilityStratification?: HospiceSuitabilityStratification;
  adlCompositeStratification?: AdlCompositeStratification;
  pdgmClinicalGroup?: PdgmClinicalGroup;
  pdgmFunctionalImpairmentLevel?: PdgmFunctionalImpairmentLevel;
  pdgmReferralSource?: ReferralSource;
  pdgmEpisodeTiming?: EpisodeTiming;
  referringFacility?: string;
  socialSupportRiskLevel?: SocialDeterminantRiskLevel;
  startOfCareDate: string;
  episodeStartDate: string;
  episodeEndDate: string;
  referringPhysicianFirstName?: string;
  referringPhysicianLastName?: string;
  payerName?: string;
  utilizationDistributions?: Record<VisitDiscipline, UtilizationDistribution>;
  medicalRecordNumber?: string;
}

export const billingPeriod = (patient: PatientReadModel): number =>
  Math.min(
    2,
    Math.floor(dayjs().diff(patient.episodeStartDate, 'days') / 30) + 1
  );

export const billingPeriodStartDate = (
  patient: PatientReadModel,
  period: number = billingPeriod(patient)
): Dayjs => dayjs(patient.episodeStartDate).add((period - 1) * 30, 'days');

export const billingPeriodEndDate = (
  patient: PatientReadModel,
  period: number = billingPeriod(patient)
): Dayjs => dayjs(patient.episodeStartDate).add(period * 30, 'days');
